@import "styles/mixins/index";

.signup-page-wrapper {
  padding: 120px 253px 105px 253px;
  @include breakpoint("max-xl") {
    padding: 120px;
  }
  @include breakpoint("max-md") {
    padding: 50px 0;
  }
  .register-success {
    display: block;
    margin-bottom: 10px;
    color: var(--primary-color);
    span {
      @include heading-4;
      color: var(--secondary-color);
    }
  }
  .register-error {
    display: block;
    margin-bottom: 10px;
    color: var(--bg-danger);
  }

  .auth-grid {
    @include grid-layout;
    grid-template-columns: minmax(0, 1fr) max(500px);
    gap: 0 map-get($pixel, md) * 6;
    @include breakpoint("max-xs") {
      grid-template-columns: repeat(2, 1fr);
      gap: 0 40px;
    }
    @include breakpoint("max-sm") {
      grid-template-columns: repeat(1, 1fr);
      gap: 46px 0;
    }
    @media screen and (max-width: 450px) {
      display: block;
    }
    .auth-grid-items {
      .select-dropdown {
        font-size: 14px;
        font-family: var(--heading-font-family), var(--default-font-family);
        padding: 11px 15px;
        cursor: pointer;

        font-weight: 400;
        color: var(--black-3);
        transition: 0.3s ease-in-out;
        line-height: 16px;
      }
      .form-control {
        margin: 0 0 map-get($pixel, sm) * 4 0;
        label{
          position: relative;
          @media (max-width: 1370px) {
            font-size: 15px;
          }
        }
        .form-control-password{
          font-size: 16px;
          display: inline-block;
          margin: 0 5px;
          font-weight: 800;
          border: 1px #000 solid;
          padding: 1px 4px;
          color: var(--black-5);
          height: 20px;
          width: 20px;
          border-radius: 50px;
          &:hover + .form-control-validation{
            display: block;
          }
        }
        .form-control-validation{
          font-size: 13px;
          // opacity: 0;
          background-color: #fff;
          border-radius: 4px;
          box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.2);
          color: var(--bg-danger);
          right: 0px;
          top: auto;
          bottom: 30px;
          font-weight: 500;
          width: 100%;
          display: none;
          padding: 5px 10px;
          position: absolute;
          transition: opacity 0.3s;
          z-index: 99;
          word-wrap: break-word;
          box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.2);
        }
        span {
          @include heading-text-xs;
          display: block;
          padding: map-get($pixel, sm) 0 0 0;
          color: var(--bg-danger);
          font-size: 11px;
        }
        .down-icon-alignment {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          @include pointer;
          right: 17px;
          i {
            color: var(--gray-text-color);
          }
        }
      }
      .loyalty-card {
        .loyalty-card-label {
          font-size: 15px;
          @include breakpoint("max-sm") {
            font-size: 13px;
          }
        }
        .loyalty-card-wrapper {
          display: grid;
          grid-template-columns: auto auto;
          gap: 12px;
          margin-top: 15px;
          @include breakpoint("max-sm") {
            grid-template-columns: auto;
          }
          @include breakpoint("max-md") {
            grid-template-columns: auto;
          }
        }
      }
      .name-wrapper {
        display: grid;
        grid-template-columns: auto auto;
        gap: 12px;
        @include breakpoint("max-sm") {
          grid-template-columns: auto;
        }
        @include breakpoint("max-md") {
          grid-template-columns: auto;
        }
      }
      .zip-code-wrapper {
        display: grid;
        grid-template-columns: auto auto;
        gap: 12px;
        margin-bottom: -51px;
        @include breakpoint("max-sm") {
          grid-template-columns: auto;
        }
        @include breakpoint("max-md") {
          grid-template-columns: auto;
        }
      }
      .password-wrapper {
        display: grid;
        grid-template-columns: auto auto;
        gap: 12px;
        @include breakpoint("max-sm") {
          grid-template-columns: auto;
        }
        @include breakpoint("max-md") {
          grid-template-columns: auto;
        }
      }
      .store-drop-down {
        min-width: 229px;
      }
      .dropdown-list-align {
        width: 100%;
      }
      .sign-up-button {
        button {
          padding: map-get($pixel, sm) * 2 map-get($pixel, md) * 3;
          background-color: var(--primary-color);
          color: var(--bg-white);
          border-radius: calc(5px - 0px);
          border: none;
          @include heading-4;
        }
      }
      .terms-conditions-wrapper {
        padding: 0 0 map-get($pixel, md) * 2 + 8px 0;
        @include flex-center;
        .terms-and-conditions-wrapper {
          @include flex-center;
          user-select: none;
          input[type="checkbox"] + #termsandconditions-lable:before {
            font-size: 14px !important;
          }
          label {
            @include flex-center;
            align-items: center;
            white-space: nowrap;
            @include breakpoint("max-sm") {
              font-size: 9px;
              flex-wrap: wrap;
            }
          }
          a {
            display: block;
            color: var(--secondary-color);
            @include ease-in-out;
            @include pointer;
            &:hover {
              text-decoration: underline;
            }
            // padding-left: 10px;
          }
        }
        div:first-child {
          padding-right: map-get($pixel, md);
        }
        div {
          @include flex-center;
          input[type="checkbox"] {
            width: 12px;
            height: 12px;
            margin: 0;
            padding: 0;
          }
          label {
            @include text-xs-normal;
            color: var(--black-200);
          }
        }
      }
      > h1 {
        @include heading-9-medium;
        color: var(--invert-secondary-color);
        margin: 0 0 7px 0;
        @include breakpoint("max-sm") {
          @include heading-2;
        }
      }
      p {
        @include text;
        font-size: calc(20px - 3px);
        color: var(--invert-secondary-color);
        margin: 0 0 map-get($pixel, md) * 3 0;
        @include breakpoint("max-sm") {
          margin: 0 0 map-get($pixel, md) * 2 + 5px 0;
        }
        span {
          color: var(--primary-color);
          @include pointer;
        }
      }
      .signup-option {
        padding: map-get($pixel, md) * 6 + 8px map-get($pixel, md) * 7;
        border: 1px solid var(--border-color-8);
        border-radius: 5px;
        @include breakpoint("max-xs") {
          padding: 20px;
        }
        @include breakpoint("max-sm") {
          padding: map-get($pixel, md) * 3 map-get($pixel, md) * 2;
        }
        button {
          padding: 10px 30px;
          border-radius: 5px;
          height: 56px;
          width: 100%;
          @include pointer;
          @include heading-text-md-semi-bold;
          @include breakpoint("max-xs") {
            font-size: 14px;
          }
        }
        .google-button {
          padding: 0 0 map-get($pixel, sm) * 3 0;
          button {
            background-color: transparent;
            border: 1px solid var(--border-color-8);
            color: var(--text-current);
            @include flex-center;
            justify-content: flex-start;
            i {
              color: var(--secondary-color);
            }
            span {
              padding-left: 10px;
            }
          }
        }
        .apple-login {
          padding: 0 0 map-get($pixel, sm) * 3 0;
          button {
            background-color: var(--bg-dark-black);
            color: var(--bg-white);
            border: none;
            @include flex-center;
            justify-content: flex-start;
            i {
              color: var(--bg-white);
            }
            span {
              padding-left: 10px;
            }
          }
        }
        .facebook-button {
          padding: 0 0 map-get($pixel, sm) * 3 0;
        }
        .facebook-button button {
          background-color: #1479ff;
          color: var(--bg-white);
          @include flex-center;
          justify-content: flex-start;
          border: none;
          i {
            color: var(--bg-white);
            font-size: 18px;
          }
          span {
            padding-left: 10px;
          }
        }
        .card-registration {
          button {
            background-color: var(--secondary-color);
            color: var(--bg-white);
            border: none;
          }
        }
      }
    }
  }
  .re-captcha {
    margin-bottom: 28px;
    @media screen and (max-width: 450px) {
      transform: scale(0.77);
      transform-origin: 0 0 ;
    }
  }
}
