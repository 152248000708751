@import "styles/mixins/index";

.department-slider {
  padding: 50px 0px;
  @include breakpoint("max-sm") {
    padding: 40px 0 30px 0;
  }
  .slick-slide {
    padding: 10px 32px;
    @include breakpoint("max-md") {
      padding: 10px 20px;
    }
    @include breakpoint("max-sm") {
      padding: 10px 12px;
    }
  }
  .slick-list {
    margin: 0 -32px;
    @include breakpoint("max-md") {
      margin: 0 -20px;
    }
    @include breakpoint("max-md") {
      margin: 0 -12px;
    }
  }
  .left-arrow-alignment {
    position: absolute;
    right: 42px;
    top: -63px;
    @include breakpoint("max-sm") {
      top: 50%;
      left: 13px;
      z-index: 9999;
    }
  }

  .right-arrow-alignment {
    position: absolute;
    right: 0;
    top: -63px;
    @include breakpoint("max-sm") {
      top: 50%;
      right: 13px;
      z-index: 9999;
    }
    svg {
      transform: rotate(178deg);
    }
  }
  .page-title {
    h1 {
      @include heading-2;
      margin: 0 0 map-get($pixel, md) * 2 + 5px 0;
      color: var(--black-text-color);
      @include breakpoint("max-sm") {
        @include heading-7;
      }
    }
  }

  .card {
    background-color: var(--bg-white);
    padding: map-get($pixel, md) map-get($pixel, md) * 2 map-get($pixel, md) * 2
      map-get($pixel, md) * 2;
    box-shadow: 0px 0px 15px rgba(129, 129, 129, 0.16);
    @include normal-radius;
    @include pointer;
    border: 1px solid var(--border-color-4);
    @include ease-in-out;
    min-height: 211px;
    &:hover {
      border: 1px solid var(--primary-color);
    }
    .card-details {
      padding: map-get($pixel, sm) + 2px 0 0 0;
      p {
        @include text-bold;
        color: var(--black-text-color);
        @include text-center;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .card-image {
      @include flex-center;
      justify-content: map-get($layout, center);
      img {
        max-width: 135px;
        max-height: 128px;
        height: 128px;
        object-fit: cover;
        padding: 15px;
      }
    }
  }
  .custom-arrow-design {
    width: 32px;
    border-radius: 50%;
    background-color: var(--bg-light-400);
    @include pointer;
    height: 32px;
    @include flex-center;
    justify-content: map-get($layout, center);
    @include ease-in-out;
    color: var(--gray-text-color);
    &:hover {
      background-color: var(--primary-color);
      color: var(--bg-white);
    }
  }
}

@media (max-width: 767px) {
  .app-banner{
    display: flex;
    flex-direction: column-reverse; 
  }
}