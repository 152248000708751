@import "styles/mixins/index";

.provide-section-page {
  padding: map-get($pixel, md) * 1 0 8px 0;
  .content{
    &.content-page-main{
      width: 100%;
      padding: 0;
      .grid{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;
        @media only screen and (max-width: 991px) {
          .about-text-main{
           margin-top: 25px;
           h2{
            @media (max-width: 767px) {
            font-size: 18px;
            }
           }
          }
       }
        img{
         margin: auto;
        
         &.image-left{
           float: none;
           width: 100%;
           height: auto;
         }
         &.image-right{
          float: none;
           width: 100%;
           height: auto;
           order: 2;
         }
        }
        @media only screen and (max-width: 991px) {
        grid-template-columns: repeat(1, 1fr);
        gap: 0;
        }
      }
    }
  } 
  @include breakpoint("max-sm") {
    padding: 20px 0 0 0;
  }

  .provide-section-title-page {
    padding: 0 0 map-get($pixel, md) * 1 0;

    @include breakpoint("max-sm") {
      padding: 0 0 map-get($pixel, md) * 4 0;
    }

    h1 {
      @include heading-9-medium;
      color: var(--invert-secondary-color);
      margin: 0;
      @include text-center;

      @include breakpoint("max-sm") {
        @include heading-2;
      }
    }
  }

  .faq {
    margin: 0 0 27px 0;
    //border: 1px solid var(--secondary-color);
    padding: 10px;

    h1 {
      text-align: center;
      color: var(--secondary-color);
      margin: 15px 0 7px 0;

    }

    h3 {
      margin: 12px 0 12px 0;
      color: var(--invert-secondary-color);
      font-size: 20px;
    }

    span {
      text-align: justify;
      line-height: 26px;
      padding: 5px;


    }

    .rewards-image {
      max-width: 100%;
      margin: auto;
      display: table;
      position: relative;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;

      img {
        display: table-cell;
        vertical-align: middle;
        margin-left: auto;
        margin-right: auto;
        width: 80%;
        object-fit: cover;

      }

    }


  }

}