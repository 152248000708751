@import "styles/mixins/index";
.facility-bar {
    padding: map-get($pixel, md) * 4 0;
    border-bottom: 1px solid var(--gray-border-color);
    @include breakpoint("max-md") {
        border: none;
    }
    @include breakpoint("max-sm") {
        border: none;
    }
    .grid {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: map-get($pixel, md) * 2 + 5px;
        @include breakpoint("max-md") {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 15px;
        }
        @media screen and (max-width: 992px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media screen and (max-width: 650px) {
            grid-template-columns: repeat(1, 1fr);
        }
        .grid-items {
            @include breakpoint("max-md") {
                padding: map-get($pixel, sm) * 3 + 3px map-get($pixel, md) * 2 + 1px;
                background-color: var(--primary-light-color);
            }
            .sub-grid {
                @include grid-layout;
                grid-template-columns: 45px minmax(0, 1fr);
                gap: 0 map-get($pixel, md) + 3px;
                align-items: start;
                .sub-grid-items {
                    img {
                        max-width: 46px;
                        max-height: 40px
                    }
                    >p {
                        @include heading-6;
                        color: var(--black-6);
                    }
                    >span {
                        @include text-sm;
                        color: var(--gray-5);
                        display: block;
                    }
                }
            }
        }
    }
}