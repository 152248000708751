@import "styles/mixins/index";

.image-card-wrapper {
  border: 1px solid var(--border-color-4);
 // display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 10px;
  height: 500px;
  overflow: auto;


  @include normal-radius;
  // @include pointer;
  @include ease-in-out;

  @include breakpoint("max-sm") {
    min-height: unset;
    border: none;
    padding: 28px;
    border: 1px solid var(--border-color-4);
    min-height: unset;
  }

  &:hover {
    border: 1px solid var(--primary-color);

    @include breakpoint("max-sm") {
      // display: none;
      border-bottom: 1px solid var(--primary-color);
    }
  }
  img {
    width: 100%;
    position: relative;
    object-fit: cover;

  }
}
