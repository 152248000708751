@import "styles/mixins/index";

.coupon-list-grid {
  @include grid-layout;
  grid-template-columns: repeat(5, 1fr);
  gap: map-get($pixel, sm) * 4;

  @include breakpoint("max-xl") {
    grid-template-columns: repeat(4, 1fr);
  }

  @include breakpoint("max-md") {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (max-width: 890px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 650px) {

    grid-template-columns: repeat(1, 1fr);
  }

  @include breakpoint("max-sm") {
    .coupon-list-grid-items:first-child {
      .product-card {
        padding-top: 0;
      }
    }
  }

  .coupon-list-grid-items {
    // width: 252px;
    margin-left: auto;
    margin-right: auto;
  }
}