@import "styles/mixins/index";

.ads-space-section-seller {
    background-color: var(--bg-light-300);
   overflow: hidden;
    @include flex-center;
    justify-content: map-get($layout, center);

    @include breakpoint("max-sm") {
        display: none;
    }
    img{
        max-width: 100%; 
        height: 300px;    
        object-fit: none;
    }

    margin:4px;

    >h1 {
        @include heading-2;
        color: var(--black-text-color);
        opacity: .22;
        padding: map-get($pixel, md) * 5 + 4px 0;
        @include breakpoint("max-md") {
            @include text-center;
        }
    }
}