@import "styles/mixins/index";

.feedback-section {
  padding: map-get($pixel, md) * 10 0;

  @include breakpoint("max-sm") {
    background-color: var(--primary-light-color);
    padding: 50px 0;
  }

  .grid {
    @include grid-layout;
    grid-template-columns: minmax(0, 1fr) 610px;
    gap: 0 94px;
    justify-content: space-between;

    @include breakpoint("max-md") {
      grid-template-columns: repeat(1, 1fr);
    }

    @include breakpoint("max-md") {
      .grid-items:last-child {
        display: none;
      }
    }

    .grid-items {
      >img {
        width: 100%;
        height: 715px;
        object-fit: cover;
        border-radius: 8px;
      }

      .form-control {
        margin: 0 0 20px 0;
        position: map-get($layout, relative);

        .show-items {
          button {
            font-size: 15px;
            line-height: 19px;
            font-family: var(--heading-font-family), var(--default-font-family);
            font-weight: 500;
            padding: 12px 17px;
            border: 1px solid var(--light-border-color);
            color: var(--black-5-light);
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: transparent;
            font-weight: 400;
            width: 100%;
            position: relative;
            transition: 0.2s ease-in-out;

            &:focus {
              border: 1px solid var(--primary-color);
            }

            span {
              padding: 0 map-get($pixel, sm) + 1px;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
          }
        }

        .text-danger {
          color: var(--bg-danger);
        }

        span {
          color: var(--bg-danger);
          font-size: 12px;
        }
      }

      .dropdown-show {
        height: 100vh;
        z-index: 999999;
      }

      .dropdown-hidden {
        z-index: 999999;
      }

      .submit-button {
        button {
          padding: 4px 30px;
          background-color: var(--primary-color);
          color: var(--text-white);
          border: none;
          height: 48px;
          @include heading-4;
          border-radius: 4px;
        }
      }

      .input-grid {
        @include grid-layout;
        grid-template-columns: repeat(2, 1fr);
        gap: 0 20px;
      }

      h1 {
        @include heading-2;
        color: var(--invert-secondary-color);
        margin: 0 0 map-get($pixel, md) 0;
      }

      p {
        @include heading-4xl;
        color: #010101;
        margin: 0 0 map-get($pixel, md) * 3 + 2px 0;
      }
    }
  }

  .select-dropdown {
    font-size: 14px;
    font-family: var(--heading-font-family), var(--default-font-family);
    padding: 11px 15px;
    cursor: pointer;

    font-weight: 400;
    color: var(--black-3);
    transition: 0.3s ease-in-out;
    line-height: 16px;


  }

  .feedback-success {
    display: block;
    margin-bottom: 10px;
    color: var(--primary-color)
  }
}