@import "styles/mixins/index";


.right-side {
  float: right;
  width: 50%;
  padding: 1%;
}

.content,
header {
  width: 80%;
  margin: 0 auto;

  @media (max-width: 767px) {
    width: 100%;
  }
}

header {
  text-align: center;
}

.content img {
  max-width: 100%;
  height: 80%;
  border-radius: 8px;
  object-fit: contain;

}

.content p {
  text-align: left;
  line-height: 30px;
}

.content {
  padding: 20px;
  overflow: hidden;

  @media (max-width: 767px) {
    padding: 0 40px;
  }

  @media (max-width: 540px) {
    padding: 0 20px;
  }
}

.content h1 {
  margin: 0 0 map-get($pixel, md) * 2 + 5px 0;
  @include heading-9-medium;
  color: var(--invert-secondary-color);

  @include breakpoint("max-sm") {
    margin: 0 0 map-get($pixel, md) * 1 + 2px 0;
    @include heading-3;
  }

  @media (max-width: 575px) {
    font-size: 18px;
    line-height: 1.2;
  }
}