@import "styles/mixins/index";

.rewardmodalContainer {
  position: fixed;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  //opacity: 0;
  //visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  transition: all 0.3s ease-in-out;
  z-index: 999;

  .modal {
    width: 950px;
    // position: relative;
    // height: 60vh;
    background-color: #fff;
    padding: 1.8rem;
    border-radius: 20px;
    // @include breakpoint("max-sm") {
    //   height: 70vh;
    // }
    &_header {
      position: relative;
      border-bottom: 1px solid #dddddd;

      &-title {
        font-size: 25px;
      }

      .close {
        position: absolute;
        top: 0;
        right: 0;
        background: transparent;

        &:hover {
          img {
            transform: scale(1.1);
          }
        }
      }
    }

    .modal-content {
      border-bottom: 1px solid #dddddd;
      padding: 1rem 0;
      line-height: 28px;
      font-size: 13px;
      width: 100%;
      height: 40vh;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 4px;
        background-color: var(--gray-9);
        border-radius: 8px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background-color: var(--primary-color);  
      }
      .content-text {
        font-size: 16px;
        text-align: center;
        @media screen and (max-width: 640px) {
        p{
          font-size: 14px;
          line-height: normal;
        }
      }
      }

      .redeem-checkbox-item-list {
        margin: 30px 0px;
        display: grid;
        grid-template-columns: auto auto;
        grid-row-gap: 25px;

        @include breakpoint("max-sm") {
          grid-template-columns: auto;
          grid-row-gap: 10px;
        }

        .redeem-item-with-checkbox:last-child {
          padding: 0;
        }
        .redeem-item-with-checkbox {
          @include flex-center;
          position: relative;
          font-size: 16px;
          padding: 0 0 map-get($pixel, sm) * 2 + 2px 0;
          label {
            display: flex;
            @include flex-center;
            align-items: flex-start;
            .redeem-list-info {
              // flex-direction: column;
              &.redeem-imput-empty{
                margin-left: 40px;
              }
              margin-left: 15px;
              @media screen and (max-width: 450px) {
                margin-left: 10px;
              }
              .redeem-d-none{
                display: none;
              }
            }
            p {
              padding-left: 0;
              line-height: 1.2;
              display: inline-block;
              position: relative;
              @media screen and (max-width: 480px) {
                font-size: 14px;
              }
            }
            .redeem-points{
              // max-width: 60px;
              min-width: 65px;
            }
            .redeem-title {
              margin-left: 10px;
          }
            .redeem-points {
              color: #62b5cf;
            }
          }
          div {
            @include flex-center;
            align-items: flex-start;
            // display: block;
            input[type="checkbox"] {
              width: 15px;
              height: 15px;
              margin: 0;
              padding: 0;
            }
          }
          input[type="checkbox"] + label:before {
            height: 30px;
            width: 30px;
            border: 3px solid var(--invert-primary-color);
            font-size: 17px !important;
          }
          input[type="checkbox"]:checked + label:after {
            top: 7px;
            border: 0;
          }
        }
      }
    }

    .expireContent {
      display: flex;
      justify-content: space-between;
      @include breakpoint("max-sm") {
        flex-direction: column;
      }
      .suggestion-txt {
        font-size: 15px;
        margin-top: 5px;
      }
      .success-msg {
        font-size: 15px;
        color: var(--primary-color);
        font-weight: bold;
        margin-top: 5px;
      }
      .error-msg {
        font-size: 15px;
        color: var(--bg-danger);
        font-weight: bold;
        margin-top: 5px;
      }
      .button-wrapper {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;

        .redeem-btn {
          padding: map-get($pixel, sm) + 3px map-get($pixel, md) + 6px;
          margin-right: 15px;
          background-color: var(--primary-light-color);
          color: var(--primary-color);
          @include text-xs-semi-bold;
          font-family: var(--text-font-family), var(--default-font-family);
          border: none;
          @include normal-radius;
          @include flex-center;
          @include pointer;
          @include ease-in-out;
          max-height: 65px;

          &:hover:not([disabled]) {
            background-color: var(--primary-color);
            color: var(--bg-white);

            // svg {
            //   stroke: var(--bg-white);

            //   g {
            //     path {
            //       fill: var(--bg-white);
            //     }
            //   }
            // }
          }
        }
        .success-btn {
          padding: map-get($pixel, sm) + 3px map-get($pixel, md) + 6px;
          margin-right: 15px;
          background-color: var(--primary-light-color);
          color: var(--primary-color);
          @include text-xs-semi-bold;
          font-family: var(--text-font-family), var(--default-font-family);
          border: none;
          @include normal-radius;
          @include flex-center;
          @include pointer;
          @include ease-in-out;
          max-height: 65px;

          &:hover:not([disabled]) {
            background-color: var(--primary-color);
            color: var(--bg-white);
          }
        }
        .modal-close {
          float: right;
          padding: 0.5rem;
          color: #fff;
          font-weight: 600;
          background: var(--secondary-color);
          border: none;
        }

        // svg {
        //   stroke: var(--primary-color);
        //   @include ease-in-out;

        //   g {
        //     path {
        //       fill: var(--primary-color);
        //     }
        //   }
        // }

        p {
          font-weight: 600;
          font-size: 16px;
          color: var(--secondary-color);
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .rewardmodalContainer .modal_header-title {
    font-size: 15px;
  }

  .rewardmodalContainer .modal {
    width: calc(100% - 20px);
  }

  .rewardmodalContainer .modal .expireContent p {
    font-size: 14px;
  }
}
