@import "styles/mixins/index";

.custom-list-section {
  padding: map-get($pixel, md) * 10 0 8px 0;
  @include breakpoint("max-sm") {
    padding: 60px 0 0 0;
  }
  .reward-section-title {
    padding: 0 0 map-get($pixel, md) * 6 0;
    @include breakpoint("max-sm") {
      padding: 0 0 map-get($pixel, md) * 4 0;
    }
    h1 {
      @include heading-9-medium;
      color: var(--invert-secondary-color);
      margin: 0;
      @include text-center;
      @include breakpoint("max-sm") {
        @include heading-2;
      }
    }
    .product-list-grid {
      @include grid-layout;
      grid-template-columns: repeat(5, 1fr);
      gap: map-get($pixel, sm) * 4;
      @include breakpoint("max-xl") {
        grid-template-columns: repeat(4, 1fr);
      }
      @include breakpoint("max-md") {
        grid-template-columns: repeat(3, 1fr);
      }
      @include breakpoint("max-sm") {
        grid-template-columns: repeat(1, 1fr);
        gap: 0;
      }
      @include breakpoint("max-sm") {
        .product-list-grid-items:first-child {
          .product-card {
            padding-top: 0;
          }
        }
      }

      .product-list-grid-items {
        .address-submit-btn{
          display: flex;
        justify-content: space-between;
        margin-top: 18px;
        align-items: center;
        }
        .add-error {
          color: var(--bg-danger);
        }
        .add-success {
          color: var(--primary-color);
        }
        .product-card {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          @include breakpoint("max-sm") {
            padding: 28px 0;
            border: none;
            border-bottom: 1px solid var(--border-color-4);
            @include grid-layout;
            grid-template-columns: 128px minmax(0, 1fr);
            gap: 0 10px;
            align-items: flex-start;
          }
          &:hover {
            border: 1px solid var(--primary-color);
            @include breakpoint("max-sm") {
              // display: none;
              border-bottom: 1px solid var(--primary-color);
            }
          }
          .product-card-image {
            padding: 16px;
            border-radius: calc(5px - 1px);

            @include breakpoint("max-sm") {
              border: 1px solid var(--border-color-4);
            }
          }
          @include breakpoint("max-sm") {
            border-bottom: 1px solid var(--border-color-4);
          }
        }
      }
      .address-submit-btn {

        button {
          padding: map-get($pixel, sm) * 2 map-get($pixel, md) * 3;
          background-color: var(--primary-color);
          color: var(--bg-white);
          border-radius: calc(5px - 0px);
          border: none;
          @include heading-text-xs;
          // margin: 12px;
        }

        div:first-child {
          // margin-right: 15px;

          button {
            background-color: var(--bg-light-200);
            color: #989898;
          }
        }

        &.disabled {
          opacity: 0.6;
          pointer-events: none;
          cursor: default;
        }
      }
      .form-control {
        margin: 0 0 map-get($pixel, md) * 1.5 0;

        span {
          @include heading-text-xs;
          display: block;
          padding: map-get($pixel, sm) 0 0 0;
          color: var(--bg-danger);
        }
      }
    }

  }
  .grid {
    @include grid-layout;
    grid-template-columns: repeat(3, 1fr);
    gap: map-get($pixel, md) * 4;
    @include breakpoint("max-md") {
      grid-template-columns: repeat(2, 1fr);
    }
    @include breakpoint("max-sm") {
      grid-template-columns: repeat(1, 1fr);
      gap: 20px;
    }
    .grid-items {
      border: 1px solid var(--border-color-9);
      background-color: var(--bg-white);
      border-radius: 7px;
      padding: 50px 50px 90px 50px;
      @include breakpoint("max-md") {
        padding: 30px;
      }
      h2 {
        @include heading-3xl;
        color: var(--invert-secondary-color);
        @include text-center;
        margin: 0 0 map-get($pixel, md) + 5px 0;
        @include breakpoint("max-md") {
          @include heading-3;
        }
      }
      p {
        @include text;
        font-size: 17px;
        line-height: 25px;
        text-align: map-get($layout, left);
        color: var(--dark-gray-300);
        @include breakpoint("max-md") {
          @include text-sm;
          line-height: 22px;
        }
      }
      .button-group-alignment {
        @include flex-between;
        padding-top: 10px;
      }
      .add-to-cart {
        button {
          padding: map-get($pixel, sm) + 3px map-get($pixel, md) + 6px;
          background-color: var(--primary-light-color);
          color: var(--primary-color);
          @include text-xs-semi-bold;
          border: none;
          @include normal-radius;
          @include flex-center;
          @include pointer;
          @include ease-in-out;
  
          &:hover:not([disabled]) {
            background-color: var(--primary-color);
            color: var(--bg-white);
  
            svg {
              stroke: var(--bg-white);
  
              g {
                path {
                  fill: var(--bg-white);
                }
              }
            }
          }
  
          span {
            padding-left: 4px;
          }
  
          svg {
            stroke: var(--primary-color);
            @include ease-in-out;
  
            g {
              path {
                fill: var(--primary-color);
              }
            }
          }
  
          &:disabled {
            cursor: default;
          }
        }
      }
        .auth-links {
          padding-left: map-get($pixel, sm) * 3 + 1px;
          ul li + li {
            border-right: map-get($pixel, null);
            padding-right: map-get($pixel, null);
          }
          a:last-child li {
            border: none;
          }
          ul {
            @include flex-center;
            a:first-child > li {
              padding-right: map-get($pixel, md);
            }
            li {
              padding-left: map-get($pixel, md);
              border-right: 1px solid var(--light-border-color);
              @include heading-text-sm-normal;
              text-transform: uppercase;
              @include pointer;
              color: var(--black-text-color);
              @include ease-in-out;
              &:hover {
                color: var(--secondary-color);
              }
            }
          }
        }
      .icon-center-align {
        @include flex-center;
        justify-content: map-get($layout, center);
        padding: 0 0 map-get($pixel, md) * 3 0;
        img {
          max-width: 276px;
          height: 210px;
          object-fit: cover;
        }
      }
    }
  }
}
