@import "styles/mixins/index";

.add-list-button {
  padding-right: map-get($pixel, md) * 5;
  position: relative;

  @include breakpoint("max-sm") {
    padding: 0;
    display: flex;

    // justify-content: flex-end;
  }

  button {
    padding: 8px 13px;
    background-color: transparent;
    color: var(--primary-color);
    @include flex-center;
    border: 1px solid var(--primary-color);

    span {
      padding-right: 9px;
    }
  }

  &.disabled {
    opacity: 0.6;
    pointer-events: none;
  }
}

.new-list-input-dropdown {
  width: 223px;
  height: 150px;
  position: absolute;
  // left: 229px;
  // top: 30px;
  bottom: 0;
  position: absolute;
  top: -81%;
  right: 0;
  left: 0;
  z-index: 99999999;

  @include breakpoint("max-sm") {
    right: 0;
    left: auto;
  }

  .new-list-input-list-style {
    filter: drop-shadow(0px 0px 20px rgba(116, 116, 116, 0.16));
    background-color: var(--bg-white);
    border: 1px solid var(--primary-color);
    border-radius: 6px;
    padding: 13px 17px;

    .save-number {
      span {
        @include text-sm-semi-bold;
        color: var(--primary-color);
        display: block;
        text-align: right;
        @include pointer;
      }
    }

    .form-control {
      margin: 0 0 9px 0;

      label {
        font-size: 15px;
        color: #000;
        line-height: 16px;
      }

      input {
        height: 24px;
        font-size: 12px;
        line-height: 12px;
      }
    }
  }
}

.add-to-list-hidden {
  max-height: 0px;
  transition: 0.3s ease-in-out;
  overflow: hidden;
}

.add-to-list-show {
  max-height: calc(100vh - 20px);
  transition: 0.3s ease-in-out;
}

.add-to-list-dropdown {
  width: 221px;
  border-radius: 6px;
  position: absolute;
  z-index: 99999;
  right: 0px;
  top: auto;
  bottom: 40px;

  .add-list-dropdown-design {
    background-color: var(--bg-white);
    border: 1px solid var(--primary-color);
    filter: drop-shadow(0px 0px 20px rgba(116, 116, 116, 0.16));
    height: 100%;
    border-radius: 6px;
    user-select: none;
    .add-cart-btn {
      margin: 10px auto;
    }
    .add-list-text {
      @include text-sm-semi-bold;
      color: var(--secondary-color);
      margin-left: 15px;
      font-size: 16px;
    }

    .dropdown-body {
      padding: 13px 18px;
      max-height: 160px;
      overflow: hidden;
      overflow-y: auto; 
      &::-webkit-scrollbar {
        width: 4px;
        background-color: #f6f6f6;
      }
      &::-webkit-scrollbar-thumb {
        background-color: var(--primary-color);
      }
      .apply-text-alignment {
        display: flex;
        justify-content: flex-end;
        p {
          @include text-sm-semi-bold;
          color: var(--primary-color);
          @include pointer;
          text-align: right;
          width: fit-content;
        }
      }
      .active-text {
        p {
          color: var(--primary-color);
        }
      }

      .relative-checkbox {
        position: relative;
        @include flex-center;
        padding: 0 0 11px 0;

        label {
          @include flex-center;
          @include text-sm-normal;
          color: var(--black-5);

          span {
            padding-left: 7px;
          }
        }
      }
    }

    .new-list-title {
      background-color: #f6f6f6;
      padding: 4px 8px;
      border-radius: 6px;
      // position: relative;

      p {
        @include text-sm-semi-bold;
        color: var(--secondary-color);
        margin: 0;
        text-align: right;
      }
    }
  }
}
