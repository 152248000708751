@import "styles/mixins/index";

.catering-product-card {
  border: 1px solid var(--border-color-4);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  // padding: 27px 18px 18px 18px;
  // min-height: 490px;
  min-height: 316px;
  @include normal-radius;
  // @include pointer;
  @include ease-in-out;
  @include breakpoint("max-sm") {
    border: none;
    padding: 28px;
    border: 1px solid var(--border-color-4);
    margin-bottom: 12px;
    min-height: 380px;
  }
  &:hover {
    border: 1px solid var(--primary-color);
    @include breakpoint("max-sm") {
      // display: none;
      border-bottom: 1px solid var(--primary-color);
    }
  }

  .product-card-image {
    // @include flex-center;
    // justify-content: map-get($layout, center);
    display: block;
    text-align: center;
    min-height: 137px;
    max-height: 137px;
    width: 100%;
    @include breakpoint("max-sm") {
      padding: 16px;
    }
    img {
      // max-width: 137px;
      max-height: 137px;
      object-fit: cover;
      padding-top: 18px;
      @include breakpoint("max-sm") {
        // min-width: 92px;
        // max-width: 92px;
        // max-height: 116px;
        // min-height: 116px;
        width: 100%;
      }
    }
  }
  .product-card-details {
    padding: 0 18px 18px 18px;
    @include breakpoint("max-sm") {
      padding: 0;
    }
    .digital-coupon-wrapper {
      display: flex;
      justify-content: space-between;
      .coupon-design {
        background-color: var(--secondary-color);
        width: fit-content;
        padding: 6px 15px 6px 9px;
        margin: 0 0 map-get($pixel, md) -18px;
        position: relative;
        @include breakpoint("max-sm") {
          margin: 0 0 10px -2px;
        }
        p {
          color: var(--bg-white);
          @include heading-text-sm-normal;
          @include breakpoint("max-md") {
            font-size: 12px;
            line-height: 16px;
          }
          span {
            @include font-medium;
          }
        }
        &::before {
          display: inline-block;
          width: 0;
          height: 0;
          border-style: solid;
          top: 0;
          border-width: 14px 12px 14px 0;
          position: absolute;
          content: "";
          right: 0;
          border-color: transparent var(--bg-white) transparent transparent;
        }
      }
      // .coupon-used-time {
      //   white-space: nowrap;
      //   font-size: 14px;
      //   margin-right: -14px;
      // }
      &.hidden {
        display: none;
        min-height: 38px;
      }
    }
    a {
      @include heading-4;
      color: var(--black-text-color);
      display: block;
      line-height: 22px;
      min-height: 36px;
      font-size: 19px;
      @include ease-in-out;
      margin: 0 0 map-get($pixel, sm) + 3px 0;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      &:hover {
        color: var(--secondary-color);
      }
    }
    .button-group-alignment {
      @include flex-between;
      padding-top: 10px;
      .add-list-button {
        padding-right: 0px;
        i {
          cursor: pointer;
        }
        button {
          border-radius: 4px;
        }
      }
      .add-to-list-show {
        overflow: visible;
      }
      .new-list-input-dropdown {
        top: auto;
        bottom: -108px;
        left: -7px;
      }
      .add-to-list-dropdown {
        right: 0px;
        top: auto;
        bottom: 40px;
        @include breakpoint("max-sm") {
          right: 5px;
        }
      }
    }
    .add-to-cart {
      button {
        padding: map-get($pixel, sm) + 3px map-get($pixel, md) + 6px;
        background-color: var(--primary-light-color);
        color: var(--primary-color);
        @include text-xs-semi-bold;
        border: none;
        @include normal-radius;
        @include flex-center;
        @include pointer;
        @include ease-in-out;
        &:hover:not([disabled]) {
          background-color: var(--primary-color);
          color: var(--bg-white);
          svg {
            stroke: var(--bg-white);
            g {
              path {
                fill: var(--bg-white);
              }
            }
          }
        }
        span {
          padding-left: 4px;
        }
        svg {
          stroke: var(--primary-color);
          @include ease-in-out;
          g {
            path {
              fill: var(--primary-color);
            }
          }
        }
        &:disabled {
          cursor: default;
        }
      }
    }
    .button-disable {
      pointer-events: none;
      opacity: 0.5;
    }
    .button-group {
      border: 1px solid var(--border-color-5);
      @include normal-radius;
      @include flex-center;
      @include pointer;
      width: fit-content;
      .action-button,
      .counter {
        width: 24px;
        height: 23px;
        @include flex-center;
        justify-content: map-get($layout, center);
      }
      .action-button {
        color: var(--gray-text-color);
        @include ease-in-out;
        &:hover {
          pointer-events: none;
          cursor: default;
        }
        i {
          font-size: 12px;
        }
      }
      .action-button-plus {
        width: 29px;
        height: 23px;
        @include flex-center;
        color: var(--gray-text-color);
        justify-content: map-get($layout, center);
        @include ease-in-out;
        &:hover {
          pointer-events: none;
          cursor: default;
        }
        i {
          font-size: 12px;
        }
      }
      .counter {
        background-color: var(--border-color);
        @include heading-text-sm;
        color: var(--black-text-color);
      }
    }
    .price {
      @include flex-center;

      h3 {
        @include text-bold;
        color: var(--color);
        padding-right: 21px;
      }
      del {
        @include text-sm-semi-bold;
        font-size: 13px;
        color: var(--gray-8);
      }
    }
    .star-text-alignment {
      @include flex-center;
      padding: 0 0 map-get($pixel, sm) + 3px 0;
      .rating-text {
        span {
          @include text-xs-normal;
          color: var(--gray-7);
        }
      }
      .star-alignment {
        @include flex-center;
        padding-right: 10px;
        i:last-child {
          margin-right: 0px;
        }
        i {
          color: var(--secondary-color);
          margin-right: 3px;
          @include pointer;
          font-size: 12px;
        }
      }
    }
    h3 {
      @include text-sm;
      padding: 0 0 map-get($pixel, sm) + 3px 0;
      display: block;
      color: var(--color);
    }
  }
}
