@import "styles/mixins/index";
.delivery-info-wrapper {
  .delivery-info-section {
    .grid {
      @include grid-layout;
      grid-template-columns: repeat(12, 1fr);
      gap: 0 map-get($pixel, md) * 4 + 5px;
      padding: 0 0 map-get($pixel, md) * 2 + 5px 0;

      @include breakpoint("max-xl") {
        gap: 25px;
      }

      .grid-items {
        grid-column: span 6;
        border-radius: map-get($pixel, md) - 2px;
        border: 1px solid var(--border-color-8);
        padding: map-get($pixel, md) * 2 + 3px map-get($pixel, md) * 2 + 7px;

        @include breakpoint("max-sm") {
          padding: map-get($pixel, sm) * 3;
        }

        @include breakpoint("max-xl") {
          grid-column: span 12;
        }

        .custom-date-picker {
          .show-items {
            position: relative;
            .dropdown-align {
              z-index: 9999999;
            }
            .list-menu {
              overflow-y: auto;
              max-height: 200px;
            }
            button {
              @include breakpoint("max-sm") {
                font-size: 13px;
              }

              span {
                display: block;
                width: 100%;
                text-align: start;
                padding-left: 15px;
              }
            }
          }
          button {
            padding: 7px 11px;
            border: 1px solid #c1c1c1;
            border-radius: calc(5px - 1px);
            width: 100%;
            color: var(--gray-3);
            @include text;
            background-color: transparent;
            @include flex-between;

            div + div {
              @include flex-center;
            }

            div ~ div i {
              color: var(--primary-color);
            }

            div {
              span {
                padding-left: map-get($pixel, md) - 2px;
              }
            }
          }
        }

        .radio-button-alignment {
          @include flex-center;

          div:first-child {
            padding-right: map-get($pixel, md) * 2 + 6px;
          }

          div {
            position: map-get($layout, relative);

            [type="radio"]:checked + label:after,
            [type="radio"]:not(:checked) + label:after {
              top: 5px;
            }

            [type="radio"]:checked + label,
            [type="radio"]:not(:checked) + label {
              padding-left: 22px;
            }
          }
        }

        p {
          @include heading-4xl;
          margin: 0 0 map-get($pixel, md) + 6px 0;
          color: var(--black-5);
        }
      }
    }
  }
}
