@import "styles/mixins/index";

.work-place-info-section {
  padding: map-get($pixel, md) * 7 0 map-get($pixel, md) * 5 + 4px 0;

  @include breakpoint("max-sm") {
    padding: map-get($pixel, md) * 5 0;
  }

  h1 {
    @include heading-9-medium;
    color: var(--black-text-color);
    margin: 0 0 map-get($pixel, md) * 3 0;

    @include breakpoint("max-sm") {
      font-size: calc(20px + (40 - 20) * ((100vw - 280px) / (767 - 280))) !important;
      line-height: 1.5 !important;
    }
  }

  .text-grid-align {
    .text-grid:last-child {
      padding: 0;
    }

    .text-grid {
      @include grid-layout;
      grid-template-columns: 45px minmax(0, 1fr);
      gap: 0 9px;
      padding: 0 0 map-get($pixel, md) * 2 0;

      @include breakpoint("max-sm") {
        grid-template-columns: 27px minmax(0, 1fr);
      }

      .text-grid-items:nth-child(2) {
        @include flex-center;
      }

      .text-grid-items {
        span {
          @include heading-5;
          color: var(--secondary-color);
          display: block;
        }

        p {
          @include text-md-normal;
          color: var(--black-200);
          margin: 0;

          @include breakpoint("max-sm") {
            @include text-sm-normal;
          }
        }
      }
    }
  }
}
