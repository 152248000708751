html,
body {
  padding: 0;
  margin: 0;
  font-family: var(--text-font-family), var(--default-font-family);
  scroll-behavior: smooth;
}

*,
*::after,
*::before {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*:focus {
  outline: 0;
  box-shadow: none;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
}

ul,
li {
  padding: 0;
  margin: 0;
  display: block;
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}

.bg-primary {
  background-color: var(--primary-color) !important;
}

button {
  cursor: pointer;
}

.markercluster-map {
  position: sticky !important;
}

.leaflet-container {
  height: 700px;
  width: 100%;
}