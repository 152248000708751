@import "styles/mixins/index";


.ebt-card-section {
    padding: 0 0 map-get($pixel, md) * 2 + 7px 0;

    @include breakpoint("max-sm") {
        padding: 27px 0;
    }

    .page-title {
        padding: 0 0 map-get($pixel, md) * 2 + 7px 0;

        h1 {
            @include heading-2;
            color: var(--invert-secondary-color);
            margin: 0;

            @include breakpoint("max-sm") {
                @include heading-3xl;
            }
        }
    }

    .ebt-card-box {
        border: 1px solid var(--border-color-8);
        border-radius: 8px;
        width: 100%;

        .sec-box {
            padding: 24px 47px 52px 47px;

            h3 {
                @include text-md-semi-bold;
                margin: 0 0 map-get($pixel, md) + 1px 0;
                color: var(--invert-secondary-color);
            }

            p {
                @include text;
                color: var(--black-3-light);
                margin: 0 0 map-get($pixel, md) * 2 - 2px 0;
            }

            a {
                @include heading-4;
                color: var(--secondary-color);
                display: block;
                @include font-normal;
                @include pointer;
            }
        }

        .first-box {
            padding: 40px 47px 24px 47px;
            border-bottom: 1px solid var(--border-color-8);

            @include breakpoint("max-sm") {
                padding: 25px;
            }

            h2 {
                @include text-md-semi-bold;
                margin: 0 0 28px 0;
                color: var(--invert-secondary-color);
            }

            .text-grid:last-child {
                padding: 0;
            }

            .text-grid {
                @include grid-layout;
                grid-template-columns: auto;
                gap: 0 map-get($pixel, md) * 8;
                padding: 0 0 map-get($pixel, md) * 2 + 5px 0;

                @include breakpoint("max-md") {
                    gap: 0;
                }

                .text-grid-items {
                    .success-message{

                        color: var(--primary-color);
                    }

                    .success-message{

                        color: var(--primary-color);
                    }
                    .error-message {
                        color: var(--bg-danger);
                    }
                    p {
                        @include heading-4;
                        margin: 0;
                    }
                }
            }
        }
    }

    .continue-shopping{
        margin-top: 12px;
        display: flex;
        justify-content: space-between;
        button {
            padding: 8px 20px;
            background-color: var(--primary-color);
            color: var(--bg-white);
            @include heading-text-sm;
            border: none;
            border-radius: 4px;

            span{
                margin-left: 4px;
            }
        }
    }
}