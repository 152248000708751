@import "styles/mixins/index";
footer {
  padding: map-get($pixel, md) * 3 0 map-get($pixel, md) * 5 + 7px 0;
  @include breakpoint("max-md") {
    padding: map-get($pixel, sm) * 3 0 + 3px map-get($pixel, md) * 3 + 4px 0;
  }
  .grid {
    @media screen and (max-width: 767px) {
      .grid-items {
        .footer-logo,
        .offer,
        .payment,
        .app-title,
        .follow-us-links,
        .question {
          text-align: center
        }
      }
    }
    @include grid-layout;
    grid-template-columns: 230px minmax(0, 1fr) 210px;
    gap: 0 map-get($pixel, md) * 8;
    @include breakpoint("max-md") {
      grid-template-columns: 230px minmax(0, 1fr);
    }
    @include breakpoint("max-sm") {
      grid-template-columns: repeat(1, 1fr);
      gap: 40px 0;
    }
    .grid-items {
      .sub-grid {
        @include grid-layout;
        grid-template-columns: repeat(3, 1fr);
        gap: 0 map-get($pixel, md) * 8;
        @include breakpoint("max-sm") {
          grid-template-columns: repeat(2, 1fr);
          gap: 11px 36px;
        }
        .sub-grid-items {
          ul {
            li:last-child {
              padding: 0;
            }
            >li {
              @include text-sm-normal;
              color: var(--black-text-color);
              padding: 0 0 map-get($pixel, sm) + 3px 0;
              @include pointer;
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
      .app-align {
        @include flex-between;
        padding: 0 0 map-get($pixel, sm) * 3 + 3px 0;
        @include breakpoint("max-sm") {
          @include flex-center;
          justify-content: center;
          gap: 10px;
        }
        img {
          margin-right: map-get($pixel, sm) + 1px;
          @include pointer;
        }
        img:last-child {
          margin: 0;
        }
      }
      .follow-us-links {
        margin-top: 10px;
        .follow-us {
          .follow-us {
            div {
              span {
                margin-bottom: 10px;
                font-weight: 500;
              }
            }
          }
          .social-icon {
            margin-top: 5px;
            display: flex;
            @media screen and (max-width: 767px) {
              justify-content: center;
            }
            a {
              height: 35px;
              width: 35px;
              text-align: center;
              color: var(--bg-white);
              background-color: var(--bg-dark-black);
              border-radius: 50px;
              margin-right: 5px;
              div {
                line-height: 2.1;
              }
              .twitter{
                line-height: 2.4;
              }
            }
          }
        }
      }
      .payment {
        h4 {
          @include heading-6;
          color: var(--black-text-color);
          margin: 0 0 map-get($pixel, md) 0;
        }
      }
      h3 {
        @include heading-6;
        color: var(--black-text-color);
        margin: 0 0 map-get($pixel, md) 0;
      }
      .footer-logo {
        img {
          @include pointer;
          // height: 55px;
          // object-fit: cover;
          //width: 177px;
          max-width: 220px;
          max-height: 70px;
        }
      }
      .offer {
        p {
          @include heading-text-sm;
          color: var(--primary-color);
        }
        a {
          @include text-sm-normal;
          @include pointer;
          display: block;
          color: #62b5cf;
        }
      }
      .question {
        padding: 0 0 map-get($pixel, sm) * 3 0;
        p {
          @include heading-text-sm;
          margin: 0 0 map-get($pixel, sm) 0;
        }
        p,
        span,
        h2 {
          color: var(--black-text-color);
        }
        span {
          @include text-sm-normal;
        }
        a {
          @include text-md-semi-bold;
          margin: 0 0 map-get($pixel, sm) - 3px 0;
          color: var(--black-text-color);
          display: block;
          @include pointer;
        }
      }
    }
  }
}