@import "styles/mixins/index";


.rewards-us-breadcrumbs {
    background-color: var(--primary-light-color);
    padding: map-get($pixel, md) * 5 55px;

    @include breakpoint("max-sm") {
        padding: 40px 0;
    }

    h1 {
        @include heading-1;
        color: var(--invert-secondary-color);
        margin: 0 0 map-get($pixel, sm) * 3 + 3px 0;

        @include breakpoint("max-sm") {
            @include heading-2;
        }
    }

    .about-breadcrumbs {
        ul {
            @include flex-center;

            li:first-child {
                color: var(--primary-color);

                span {
                    padding-left: map-get($pixel, sm);
                    display: block;
                }

                i {
                    color: var(--primary-color);
                }
            }

            li {
                @include text;
                color: var(--gray-text-color);
                padding-right: map-get($pixel, sm) * 2;
                @include flex-center;
                @include pointer;
            }

            i {
                color: var(--gray-text-color);
            }
        }
    }
}

.rewards-add-space {
    background-color: var(--bg-light);
   img { 
    width: -webkit-fill-available;
   }
   
    h1 {
        @include heading-3;
        @include text-center;
        color: var(--gray-text-color);
        padding: 60px 0;
    }
}