@import "styles/mixins/index";
.modal__btn {
    a {
        cursor: pointer;
    }
}
.modalContainer {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    //opacity: 0;
    //visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, .5);
    transition: all 0.3s ease-in-out;
    z-index: 999;
    .modal {
        width: 950px;
        // position: relative;
        // height: 60vh;
        height: auto;
        background-color: #fff;
        padding: 2rem;
        border-radius: 20px;
        &_header {
            position: relative;
            border-bottom: 1px solid #dddddd;
            &-title {
                text-align: center;
                font-size: 20px;
            }
            .close {
                position: absolute;
                top: 0;
                right: 0;
                background: transparent;
                &:hover {
                    img {
                        transform: scale(1.1);
                    }
                }
            }
        }
        &_content {
            border-bottom: 1px solid #dddddd;
            padding: 1rem 0;
            line-height: 28px;
            font-size: 13px;
            display: inline-flex;
            height: 40vh;
            width: 100%;
            .content-image {
                width: 40%;
                text-align: center;
                float: left;
                margin: 1%;
                img {
                    max-width: 100%;
                    max-height: 80%;
                    object-fit: cover;
                }
            }
            .content-desc {
                width: 60%;
                text-align: justify;
                //justify-content: ;
                overflow-y: auto;
                overflow-x: hidden;
                padding-right: 15px;
                padding-bottom: 5px;
                line-height: 28px;
                font-family: var(--text-font-family), var(--default-font-family);
                font-size: 16px;
            }
        }
        .expireContent {
            margin: 12px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            @media (max-width: 435px) {
                flex-wrap: wrap;
            }
            .addBtn {
                padding: 0;
                margin-right: 10px;
                display: inline-block;
                button {
                    padding: map-get($pixel, sm) + 3px map-get($pixel, md) + 6px;
                    background-color: var(--primary-light-color);
                    color: var(--primary-color);
                    @include text-xs-semi-bold;
                    font-family: var(--text-font-family), var(--default-font-family);
                    border: none;
                    @include normal-radius;
                    @include flex-center;
                    @include pointer;
                    @include ease-in-out;
                    &:hover:not([disabled]) {
                        background-color: var(--primary-color);
                        color: var(--bg-white);
                        svg {
                            stroke: var(--bg-white);
                            g {
                                path {
                                    fill: var(--bg-white);
                                }
                            }
                        }
                    }
                }
                svg {
                    stroke: var(--primary-color);
                    @include ease-in-out;
                    g {
                        path {
                            fill: var(--primary-color);
                        }
                    }
                }
                span {
                    padding-left: 4px;
                }
            }
            p {
                font-weight: 600;
                font-size: 16px;
                color: var(--secondary-color);
            }
        }
        &_footer {
            padding: 0;
            // padding-bottom: 12px;
            // margin-top: -120px;
            button {
                float: right;
                padding: 0.5rem;
                color: #fff;
                //border-radius: 8px;
            }
            .modal-close {
                // background-color: transparent;
                border-radius: 4px;
                display: inline-block;
                font-weight: 600;
                background: var(--secondary-color);
                border: none;
            }
        }
    }
}
@media screen and (max-width: 700px) {
    .modalContainer .modal_header-title {
        font-size: 18px;
    }
    .modalContainer .modal {
        width: calc(100% - 20px);
    }
    .modalContainer .modal .expireContent p {
        font-size: 14px;
        @media (max-width: 435px) {
            margin-bottom: 15px;
        }
    }
}