@import "styles/mixins/index";



  .catering-checkbox-group {
    padding: 0 0 12px 0;

    @include breakpoint("max-sm") {
      padding: 0 0 20px 0;
    }

    p {
      @include heading-4;
      margin: 0 0 map-get($pixel, md) 0;
      color: var(--black-5-light);

      span {
        color: var(--bg-danger);
      }
    }
    .catering-checkbox-group-item-list {
        display: grid;
        grid-template-columns: auto;
        
        @include breakpoint("max-sm") {
            grid-template-columns: auto;
          }

        .reorder-dropdown-subheading {
          display: block;
          color: var(--gray-400);
          margin: 10px 0;
          font-weight: 600;
        }
      
        .catering-item-with-checkbox:last-child {
          padding: 0;
        }
        .catering-item-with-checkbox {
          @include flex-center;
          position: relative;
          padding: 0 0 map-get($pixel, sm) * 2 + 2px 0;
          label {
            @include flex-center;
            span {
              padding-left: 7px;
            }
          }
          div {
            @include flex-center;
            input[type="checkbox"] {
              width: 15px;
              height: 15px;
              margin: 0;
              padding: 0;
            }
          }
        }
      }

  }