@import "styles/mixins/index";

.payment-method-section {
  padding: 25px 30px;

  @include breakpoint("max-sm") {
    padding: 15px;
  }

  h1 {
    @include heading-4xl;
    margin: 0 0 1rem 0;
    color: var(--black-5);
  }

  .radio-alignment {
    padding: 0 0 20px 0;
    @include flex-center;

    div {
      position: relative;

      span {
        @include heading-text-sm;
        display: block;
        padding-left: 15px;
        color: var(--primary-color);
      }
    }

    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label {
      padding-left: 25px;
    }

    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
      top: 5px;
    }
  }
}
