@import "styles/mixins/index";

.bestseller-slider {
  // padding: map-get($pixel, md) * 2 0;
  padding-bottom: map-get($pixel, md) * 2;

  @include breakpoint("max-sm") {
    padding: 40px 0 60px 0;
  }

  .slick-slide {
    padding: 10px 15px;

    @include breakpoint("max-md") {
      padding: 10px 20px;
    }

    @include breakpoint("max-sm") {
      padding: 10px 12px;
    }
  }

  .slick-list {
    margin: 0 -15px;

    @include breakpoint("max-md") {
      margin: 0 -20px;
    }

    @include breakpoint("max-md") {
      margin: 0 -12px;
    }
  }

  .left-arrow-alignment {
    position: absolute;
    right: 42px;
    top: -63px;

    @include breakpoint("max-sm") {
      top: -54px;
    }
  }

  .right-arrow-alignment {
    position: absolute;
    right: 0;
    top: -63px;

    @include breakpoint("max-sm") {
      top: -54px;
    }

    svg {
      transform: rotate(178deg);
    }
  }

  .sellerpage-title {
    margin: 15px 0;
    position: relative;
    h1 {
      @include heading-2;
      // margin: 0 0 map-get($pixel, md) * 3 0;
      color: var(--black-text-color);

      @include breakpoint("max-sm") {
        @include heading-7;
      }
    }

    p {
      @include heading-2;
      margin: 0 0 map-get($pixel, md) * 1-6 0;
      color: var(--black-text-color);

      @include breakpoint("max-sm") {
        @include heading-7;
      }
    }

    span {
      font-family: var(--heading-font-family), var(--default-font-family);
      color: var(--gray-400);
    }

    a {
      position: absolute;

      right: 0;
      bottom: -14px;
      font-size: 16px;
      font-family: var(--heading-font-family), var(--default-font-family);
      margin: -58px 0 0 0;
      font-weight: 600;

      @include breakpoint("max-sm") {
        bottom: -18px;
      }
    }

    a:hover {
      color: var(--secondary-color);
    }
  }

  .seller-card {
    background-color: var(--bg-white);
    padding: map-get($pixel, md) map-get($pixel, md) * 2 map-get($pixel, md) * 2
      map-get($pixel, md) * 2;
    box-shadow: 0px 0px 15px rgba(129, 129, 129, 0.16);
    @include normal-radius;
    @include pointer;
    border: 1px solid var(--border-color-4);
    @include ease-in-out;
    min-height: 211px;

    &:hover {
      border: 1px solid var(--primary-color);
    }

    .sellercard-details {
      padding: map-get($pixel, sm) + 2px 0 0 0;

      p {
        @include text-bold;
        color: var(--black-text-color);
        @include text-center;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .sellercard-image {
      @include flex-center;
      justify-content: map-get($layout, center);

      img {
        max-width: 135px;
        max-height: 128px;
        height: 128px;
        object-fit: cover;
        padding: 15px;
      }
    }
  }

  .custom-arrow-design {
    width: 32px;
    border-radius: 50%;
    background-color: var(--bg-light-400);
    @include pointer;
    height: 32px;
    @include flex-center;
    justify-content: map-get($layout, center);
    @include ease-in-out;
    color: var(--gray-text-color);

    &:hover {
      background-color: var(--primary-color);
      color: var(--bg-white);
    }
  }
}
.bestseller-slider-no-bottom-space {
  padding-top: map-get($pixel, md) * 2;
  padding-bottom: 0;
}
