@import "styles/mixins/index";

.slick-slide div {
  outline: none;
}

.slick-dots {
  li {
    margin: 0px;
    > button::before {
      font-size: 14px;
      @include breakpoint("max-sm") {
        font-size: 12px;
      }
    }
  }
  li.slick-active > button::before {
    opacity: 1;
    color: var(--secondary-color);
  }
}

.singlebanner-banner-section {
  padding: 20px 0px 0px 0px;
  .slick-dots {
    bottom: 24px;
    @include breakpoint("max-md") {
      bottom: 10px;
    }
  }
  .singlebanner-banner {
    position: relative;
    width: 100%;
    img{
      max-width: 100%;
      height: auto;
      width: 100%;
      @media only screen and (min-width: 1440px) {
        height: 600px;
      }
      @media only screen and (max-width: 576px) {
        height: 192px;
      }
    }
    .singlebanner-banner-grid {
        .singlebanner-banner-grid-items{
          position: absolute;
          width: 50%;
          top: 0;
          bottom: 0;
          margin: auto;
          height: max-content;
          left: 8%;
          text-align: left;
        }
      @include grid-layout;
      grid-template-columns: minmax(0, 1fr) 724px;
      gap: 0 204px;
      justify-content: space-between;
      padding-left: 130px;
      display: flex;
      flex-direction: column;
      align-items: center;
      @include breakpoint("max-xl") {
        padding: 0;
        gap: 0 60px;
      }
      @include breakpoint("max-md") {
        // padding: map-get($pixel, md) * 4 + 7px 0 0 0;
      }
      @include breakpoint("max-sm") {
        height: auto;
        // padding: 20px 0 0 0;
      }
      @include breakpoint("max-md") {
        grid-template-columns: repeat(1, 1fr);
      }
      @media only screen and (min-device-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
      }
      .singlebanner-banner-grid-items:nth-child(2) {
        padding: map-get($pixel, md) * 3 + 2px 0 0 0;
        height: 100%;
        @include breakpoint("max-md") {
          display: none;
        }
        @media only screen and (min-device-width: 768px) {
          display: block;
        }
      }
      .singlebanner-banner-grid-items {
        img {
          height: auto;
          max-height: 89%;
          max-width: 100%;
          width: 100%;
          // object-fit: cover;
        }
        > h1,
        h2 {
          @include heading-1;
          color: var(--slider-text-h1-color);
          @media screen and (max-width: 1024px) {
            font-size: calc(9px + (35 - 9) * ((100vw - 280px) / (1024 - 280)));  
            line-height: 1.2;

          }
          h1{
            @media screen and (max-width: 768px) {
              font-size: calc(10px + (20 - 10) * ((100vw - 280px) / (1024 - 280)));  
              line-height: 1.2;
  
            } 
          }
        }
        h2 {
          color: var(--primary-color);
          margin: 0 0 map-get($pixel, sm) * 3 0;
          @media screen and (max-width: 1024px) {
            font-size: calc(12px + (43 - 12) * ((100vw - 280px) / (1024 - 280)));
            margin: 0 0 calc(0px + (18 - 0) * ((100vw - 280px) / (1024 - 280))) 0;
          }
         
        }
        > p {
          @include text-lg;
          margin: 0 0 map-get($pixel, md) * 4 0;
          color: var(--slider-text-p-color);
          @media screen and (max-width: 1024px) {
            font-size: calc(9px + (18 - 9) * ((100vw - 280px) / (1024 - 280)));
            line-height: 1.2;
            margin-bottom: calc(3px + (18 - 3) * ((100vw - 280px) / (1024 - 280)));
          }
        }
        > button {
          background-color: var(--primary-color);
          padding: map-get($pixel, sm) * 2 - 1px map-get($pixel, md) * 3;
          border: none;
          color: var(--text-white);
          @include text-semi-bold;
          font-family: var(--heading-font-family);
          text-transform: capitalize;
          border-radius: 4px;
          @include breakpoint("max-sm") {
            font-size: calc(8px + (14 - 8) * ((100vw - 280px) / (767 - 280)));
            padding: 5px 10px;
            line-height: normal;

          }
        }
      }
    }
  }
}
