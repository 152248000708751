@import "styles/mixins/index";



.download-checkout-invoice-wrapper {
  padding: 30px 0;


  @include breakpoint("max-md") {
    padding: 40px 0;
  }

  .checkout-invoice-header {
    background-color: #defff0;
    padding: 20px 40px;
    @include flex-center;
    justify-content: space-between;
    border-radius: 8px 8px 0 0;

    @include breakpoint("max-md") {
      padding: 25px;
    }

    @include breakpoint("max-sm") {
      padding: 15px;
      display: block;
    }

    div {
      .date {
        @include text-sm;
        margin: 0 0 10px 0;
        color: var(--invert-secondary-color);
        @include font-medium;
      }

      .order {
        @include text-sm;
        color: var(--primary-color);
        margin: 0;
      }
    }
  }
}

.download-invoice-body-wrapper {
  padding: 0px 25px;
  border-bottom: 1px solid var(--border-color-8);
  font-size: 14px;
  @include breakpoint("max-md") {
    padding: 25px;
  }

  .invoice-grid-wrapper {
    padding: 0 0 20px 0;
  }

  .invoice-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);

 
    .invoice-grid-items {
      display: flex;
      p {
        // @include heading-7-medium;
        margin: 0 10px 1px 0;
        color: var(--invert-secondary-color);
      }

      .address {
        // @include text-md-normal;
        max-width: 400px;
        margin: 0;
        color: var(--black-light-300);
      }

      span {
        display: block;
        padding: 0 0 10px 0;
        // @include text-md-normal;
        // font-size: 21px;
        color: var(--black-light-300);
      }
    }
  }
}

.download-invoice-table-wrapper {
  padding: 25px;


  .table-responsive {
    @include breakpoint("max-sm") {
      width: 100%;
      overflow: scroll;
    }
  }

  table {
    width: 100%;
    border: 1px solid var(--border-color-8);
    border-collapse: collapse;

    @include breakpoint("max-sm") {
      white-space: nowrap;
    }

    tr {
      td {
        padding: 8px 20px;
       font-size: 14px;
        color: var(--black-3-light);

        span {
          display: block;
        }
      }
    }

    .green-text-color {
      color: var(--primary-color);
    }

    tr:first-child {
      background-color: var(--primary-color);
      border-radius: 4px 4px 0 0;

      th {
        color: var(--text-white);
        // @include text-md-normal;
      }

      td {}
    }

    tr {
      border-bottom: 1px solid var(--border-color-8);

      th {
        padding: 13px 20px;
      }
    }
  }
}

.download-important-note-wrapper {
  @include flex-between;
  padding: 0 25px;
  font-size: 14px;
 

  .follow-us-wrapper {
    justify-content: flex-end;
    @include flex-center;

    .social-media-wrapper {
      @include flex-center;

      div:first-child {
        margin-right: 10px;
      }

      div {
        width: 35px;
        height: 35px;
        color: var(--text-white);
        @include flex-center;
        @include pointer;
        justify-content: center;
        border-radius: 50%;
        background-color: var(--secondary-color);
      }
    }

    span {
      // @include heading-text-sm;
      color: var(--black-3);
      padding-right: 15px;
      display: block;
    }
  }

  .download-thank-text {
  

    h6 {
      // @include heading-7-medium;
      font-size: 19px;
      line-height: 22px;
      color: var(--invert-secondary-color);
      margin: 0 0 20px 0;
    }
  }

  .notes-text {
    h5 {
      // @include text-lg;
      // @include font-medium;
      color: var(--invert-secondary-color);
      margin: 0 0 15px 0;
    }

    ul {
      display: block;
      margin: 0;
      padding: 0;
      padding-left: 1.25rem;

      li {
       
        // @include text;
        color: #565656;
        margin: 0 0 10px 0;
      }
    }
  }
}



.checkout-card {
  .item-details {
    display: flex;
    justify-content: space-between;
  }

  border: 1px solid var(--border-color-4);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  padding: 27px 18px 18px 18px;
  // min-height: 490px;
  min-height: auto;
  @include normal-radius;
  // @include pointer;
  @include ease-in-out;

  @include breakpoint("max-sm") {
    min-height: unset;
    border: none;
    padding: 28px;
    border: 1px solid var(--border-color-4);
    min-height: unset;
  }

  &:hover {
    border: 1px solid var(--primary-color);

    @include breakpoint("max-sm") {
      // display: none;
      border-bottom: 1px solid var(--primary-color);
    }
  }

  @keyframes check {
    100% {
      stroke-dashoffset: 4.5px;
    }
  }

  html {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
  }

  * {
    box-sizing: inherit;

    &:before,
    &:after {
      box-sizing: inherit;
    }
  }

  body {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.checkout-items-card {
  display: none;

  @include breakpoint("max-sm") {
    display: block;
  }

  .invoice-wrapper {
    .checkout-invoice-information {
      border: 1px solid var(--border-color-8);
      border-radius: calc(10px - 2px);
      margin: 0 0 map-get($pixel, md) * 3 0;
    }

    .checkout-invoice-text-alignment {
      border-bottom: none;
      padding: 15px;
      border-bottom: 1px solid var(--border-color-8);
      display: flex;
      justify-content: space-between;

      div {
        p {
          @include heading-4;
          line-height: 22px;
          @include flex-center;
          color: #3d3d3d;

          @include breakpoint("max-sm") {
            @include heading-text-sm;
          }
        }

        h2 {
          font-size: 20px;
          color: var(--primary-color);
        }
      }
    }
  }
}