@import "styles/mixins/index";

.testimonials-wrapper {
  margin: map-get($pixel, md) * 9 + 2px 0 0 0;
  background-color: var(--primary-light-color);
  padding: 65px 0 155px 0;
  @include breakpoint("max-sm") {
    padding: 50px 0 95px 0;
    margin: 62px 0 0 0;
  }
  .slick-dots {
    @include breakpoint("max-sm") {
      bottom: -50px;
    }
  }
  .slick-slide {
    padding: 10px;
  }
  .slick-list {
    margin: -10px;
  }

  .testimonials-card {
    background-color: var(--bg-white);
    padding: map-get($pixel, md) * 2 + 8px map-get($pixel, md) * 2 + 4px;
    box-shadow: (0px 0px 10px rgba(104, 104, 104, 0.16));
    border-radius: 8px;
    min-height: 311px;
    .icon-align {
      padding: 0 0 map-get($pixel, md) - 1px 0;
    }
    p {
      @include text;
      color: var(--black-text-color);
      line-height: 28px;
      margin: 0 0 35px 0;
      @include breakpoint("max-sm") {
        margin: 0 0 30px 0;
      }
    }
    a {
      @include text;
      color: var(--gray-5);
      display: block;
    }
    span {
      @include heading-text-md-medium;
      display: block;
      padding: 0 0 map-get($pixel, sm) 0;
      color: var(--secondary-color);
    }
  }
  .page-title {
    padding: 0 0 map-get($pixel, md) * 2 + 8px 0;
    @include breakpoint("max-sm") {
      padding: 0 0 34px 0;
    }
    p {
      @include text-center;
      @include heading-text-md-medium;
      letter-spacing: 0.1em;
      margin: 0 0 8px 0;
      color: var(--black-4);
      text-transform: uppercase;
      @include breakpoint("max-sm") {
        @include heading-text-sm-normal;
        @include font-medium;
      }
    }
    h2 {
      @include heading-2;
      color: var(--black-4);
      @include text-center;
      @include breakpoint("max-sm") {
        @include heading-7;
        @include font-medium;
      }
    }
  }

  .custom-arrow-design {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: var(--bg-white);
    @include flex-center;
    justify-content: center;
    @include pointer;
    color: var(--primary-color);
    @include ease-in-out;
    @include breakpoint("max-md") {
      display: none;
    }
    &:hover {
      background-color: var(--primary-color);
      color: var(--bg-white);
    }
  }

  .left-arrow-alignment {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -50px;
    @include breakpoint("max-md") {
      display: none;
    }
  }

  .right-arrow-alignment {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -50px;
    @include breakpoint("max-md") {
      display: none;
    }
  }
}
.testimonial-space {
  margin-top: 100px;
}
