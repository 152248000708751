@import "styles/mixins/index";

.weekly-ads-inner-section {
  padding: map-get($pixel, md) * 3 0;

  @include breakpoint("max-sm") {
    padding: 50px 0;
  }
  .valid-weekly-ad-item-price {
    color: var(--primary-color);
    padding: 10px 0;
    text-align: center;
    @include breakpoint("max-sm") {
      font-size: 16px;
    }
  }
  .cus-slider-arrow-design {
    width: 68px;
    height: 68px;
    background-color: var(--primary-color);
    @include flex-center;
    justify-content: center;
    @include pointer;
    border-radius: 50%;

    @include breakpoint("max-sm") {
      width: 40px;
      height: 40px;
    }

    i {
      color: var(--bg-white);
      font-size: 25px;

      @include breakpoint("max-sm") {
        font-size: 14px;
      }
    }
  }

  .left-arrow-align {
    position: absolute;
    top: 50%;
    z-index: 9999;
    left: 0;
    transform: translateY(-50%);
  }

  .right-arrow-align {
    position: absolute;
    top: 50%;
    z-index: 9999;
    right: 0;
    transform: translateY(-50%);
  }

  .weekly-ads-wrapper {
    // padding: 0 150px;

    @include breakpoint("max-md") {
      padding: 0 40px;
    }

    @include breakpoint("max-sm") {
      padding: 0;
    }

    .weekly-ads-wrapper-inner {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      @include breakpoint("max-md") {
        display: block;
      }
      @include breakpoint("max-sm") {
        display: block;
      }
      .weekly-add-inner-slider {
        width: 80%;
        margin-right: 15px;
        @include breakpoint("max-md") {
          width: 100%;
          margin: 0 auto;
        }
        @include breakpoint("max-sm") {
          width: 100%;
          margin: 0 auto;
        }
      }
    }
    .ads-image-style {
      img {
        width: 100%;
        // height: 1200px;
        object-fit: cover;

        @include breakpoint("max-md") {
          // height: 800px;
        }

        @include breakpoint("max-sm") {
          // height: 450px;
        }
      }
    }
  }
}
