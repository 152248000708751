@import "styles/mixins/index";

.backBtn {
  padding: 12px;
  color: var(--text-white);
  border-radius: 4px;
  // margin-right: 15px;
  margin-bottom: 12px;
  border: none;
  @include heading-text-sm;
  background-color: var(--primary-color);
  float: right;
}

.checkout-invoice-wrapper {
  padding: 60px 0;
display: flex;
flex-direction: column;

  @include breakpoint("max-md") {
    padding: 40px 0;
  }

  .checkout-invoice-header {
    background-color: #defff0;
    padding: 49px 195px;
    @include flex-center;
    justify-content: space-between;
    border-radius: 8px 8px 0 0;

    @include breakpoint("max-md") {
      padding: 25px;
    }

    @include breakpoint("max-sm") {
      padding: 15px;
      display: block;
    }
    img{
      max-width: 220px;
      max-height: 70px;
    }

    div {
      p {
        @include text-md;
        margin: 0 0 10px 0;
        color: var(--invert-secondary-color);
        @include font-medium;
      }

      h2 {
        @include heading-5;
        color: var(--primary-color);
        margin: 0;
      }
    }
  }
}

.invoice-body-wrapper {
  padding: 35px 200px 48px 200px;
  border-bottom: 1px solid var(--border-color-8);

  @include breakpoint("max-md") {
    padding: 25px;
  }

  .invoice-grid-wrapper {
    padding: 0 0 20px 0;
  }

  .invoice-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 180px;

    @include breakpoint("max-sm") {
      gap: 20px;
      grid-template-columns: repeat(1, 1fr);
    }

    .invoice-grid-items {
      display: flex;
      flex-direction: column;
      p {
        @include heading-7-medium;
        margin: 0 0 1px 0;
        color: var(--invert-secondary-color);
      }

      h5 {
        @include text-md-normal;
        max-width: 400px;
        margin: 0;
        font-size: 17px;
        color: var(--black-light-300);
      }

      span {
        display: block;
        padding: 0 0 10px 0;
        @include text-md-normal;
        font-size: 21px;
        color: var(--black-light-300);
      }
      .cancel-order-button{
        margin-top: 8px;
        width: fit-content;
        padding: 10px 20px;
        border: 1px solid var(--primary-color);
        color: var(--primary-color);
        background-color: transparent;
        height: 40px;
        cursor: pointer;
        border-radius: calc(5px - 1px);
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 16px;
        font-family: var(--heading-font-family), var(--default-font-family);
        font-weight: 400;
        justify-content: center;
      }
    }
  }
}

.invoice-table-wrapper {
  padding: 40px 200px;

  @include breakpoint("max-md") {
    padding: 25px;
  }

  @include breakpoint("max-sm") {
    display: none;
  }

  .table-responsive {
    @include breakpoint("max-sm") {
      width: 100%;
      overflow: scroll;
    }
  }

  table {
    width: 100%;
    border: 1px solid var(--border-color-8);
    border-collapse: collapse;

    @include breakpoint("max-sm") {
      white-space: nowrap;
    }

    tr {
      .last-amount{
        text-align: right;
      }
      td {
        padding: 13px 20px;
        @include heading-4;
        color: var(--black-3-light);
        span {
          display: block;
        }
      }
    }

    .green-text-color {
      color: var(--primary-color);
    }

    tr:first-child {
      background-color: var(--primary-color);
      border-radius: 4px 4px 0 0;

      th {
        color: var(--text-white);
        @include text-md-normal;
      }

      td {}
    }

    tr {
      border-bottom: 1px solid var(--border-color-8);

      th {
        padding: 13px 20px;
      }
    }
  }
}

.important-note-wrapper {
  @include flex-between;
  padding: 0 200px 100px 200px;

  @include breakpoint("max-md") {
    padding: 25px;
  }

  @include breakpoint("max-sm") {
    padding: 15px;
    display: block;
  }

  .follow-us-wrapper {
    justify-content: flex-end;
    @include flex-center;

    .social-media-wrapper {
      @include flex-center;

      div:first-child {
        margin-right: 10px;
      }

      div {
        width: 35px;
        height: 35px;
        color: var(--text-white);
        @include flex-center;
        @include pointer;
        justify-content: center;
        border-radius: 50%;
        background-color: var(--secondary-color);
      }
    }

    span {
      @include heading-text-sm;
      color: var(--black-3);
      padding-right: 15px;
      display: block;
    }
  }

  .thank-text {
    @include breakpoint("max-sm") {
      padding: 20px 0 0 0;
    }

    h6 {
      @include heading-7-medium;
      font-size: 19px;
      line-height: 22px;
      color: var(--invert-secondary-color);
      margin: 0 0 20px 0;
    }
  }

  .notes-text {
    h5 {
      @include text-lg;
      @include font-medium;
      color: var(--invert-secondary-color);
      margin: 0 0 15px 0;
    }

    ul {
      display: block;
      margin: 0;
      padding: 0;
      padding-left: 1.25rem;

      li {
        display: checkout-item;
        checkout-style: disc;
        @include text;
        color: #565656;
        margin: 0 0 10px 0;
      }
    }
  }
}

.invoice-footer-button {
  @include flex-center;
  padding: 0 0 80px 0;
  justify-content: center;

  @include breakpoint("max-sm") {
    padding: 0 0 40px 0;
  }

  button:last-child {
    margin: 0;
    background-color: var(--primary-color);
  }

  button:first-child {
    background-color: var(--secondary-color);
  }

  button {
    padding: 12px;
    color: var(--text-white);
    border-radius: 4px;
    margin-right: 15px;
    border: none;
    @include heading-text-sm;

    span {
      padding-left: 7px;
    }
  }
}

.checkout-card {
  margin-bottom: 12px;
  .item-details {
    display: flex;
    justify-content: space-between;
  }

  border: 1px solid var(--border-color-4);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  padding: 27px 18px 18px 18px;
  // min-height: 490px;
  min-height: auto;
  @include normal-radius;
  // @include pointer;
  @include ease-in-out;

  @include breakpoint("max-sm") {
    min-height: unset;
    border: none;
    padding: 28px;
    border: 1px solid var(--border-color-4);
    min-height: unset;
  }

  &:hover {
    border: 1px solid var(--primary-color);

    @include breakpoint("max-sm") {
      // display: none;
      border-bottom: 1px solid var(--primary-color);
    }
  }

  @keyframes check {
    100% {
      stroke-dashoffset: 4.5px;
    }
  }

  html {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
  }

  * {
    box-sizing: inherit;

    &:before,
    &:after {
      box-sizing: inherit;
    }
  }

  body {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.checkout-items-card {
  display: none;

  @include breakpoint("max-sm") {
    display: block;
  }

  .invoice-wrapper {
    .checkout-invoice-information {
      border: 1px solid var(--border-color-8);
      border-radius: calc(10px - 2px);
      margin: 0 0 map-get($pixel, md) * 3 0;
    }

    .checkout-invoice-text-alignment {
      border-bottom: none;
      padding: 15px;
      border-bottom: 1px solid var(--border-color-8);
      display: flex;
      justify-content: space-between;

      div {
        p {
          @include heading-4;
          line-height: 22px;
          @include flex-center;
          color: #3d3d3d;

          @include breakpoint("max-sm") {
            @include heading-text-sm;
          }
        }

        h2 {
          font-size: 20px;
          color: var(--primary-color);
        }
      }
    }
  }
}