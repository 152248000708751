@import "styles/mixins/index";


.dashboard-section {
    padding: 0 0 map-get($pixel, md) * 2 + 7px 0;

    @include breakpoint("max-sm") {
        padding: 27px 0;
    }

    .page-title {
        padding: 0 0 map-get($pixel, md) * 2 + 7px 0;

        h1 {
            @include heading-2;
            color: var(--invert-secondary-color);
            margin: 0;

            @include breakpoint("max-sm") {
                @include heading-3xl;
            }
        }
    }

    .dashboard-box {
        border: 1px solid var(--border-color-8);
        border-radius: 8px;
        width: 100%;

        .sec-box {
            padding: 24px 47px 52px 47px;

            h3 {
                @include text-md-semi-bold;
                margin: 0 0 map-get($pixel, md) + 1px 0;
                color: var(--invert-secondary-color);
            }

            p {
                @include text;
                color: var(--black-3-light);
                margin: 0 0 map-get($pixel, md) * 2 - 2px 0;
            }

            a {
                @include heading-4;
                color: var(--secondary-color);
                display: block;
                @include font-normal;
                @include pointer;
            }
        }

        .first-box {
            padding: 40px 47px 24px 47px;
            border-bottom: 1px solid var(--border-color-8);

            @include breakpoint("max-sm") {
                padding: 25px;
            }
            button{
                span{
                    font-size: 17px;
                    @include breakpoint("max-sm") {
                        font-size: 13px;
                    }
                }
            }
            h2 {
                @include text-md-semi-bold;
                margin: 0 0 28px 0;
                color: var(--invert-secondary-color);
            }

            .text-grid:last-child {
                padding: 0;
            }

            .text-grid {
                @include grid-layout;
                grid-template-columns: 50px minmax(0, 1fr);
                gap: 0 map-get($pixel, md) * 8;
                padding: 0 0 map-get($pixel, md) * 2 + 5px 0;

                @include breakpoint("max-md") {
                    gap: 0;
                }

                .text-grid-items {
                    &.bg-image{
                        max-width: 700px;
                        @media screen and (max-width: 1200px) {
                            max-width: 250px;
                            margin-left: -11px !important;
                        }
                        @media screen and (max-width: 320px) {
                            max-width: 200px;
                        }
                    }
                    p {
                        @include heading-4;
                        color: var(--invert-secondary-color);
                        margin: 0;
                    }

                    span {
                        @include heading-4;
                        @include font-normal;
                        word-break: break-all;
                        color: var(--invert-secondary-color);
                        display: block;
                    }
                }
            }
        }
    }
}