@import "styles/mixins/index";
.cancel-order-modal-body {
  padding: 30px;
  text-align: center;
  .button-primary {
    padding: 8px 20px;
    background-color: var(--primary-color);
    color: var(--bg-white);
    font-size: 15px;
    line-height: 19px;
    font-family: var(--heading-font-family), var(--default-font-family);
    font-weight: 500;
    border: none;
    border-radius: 4px;
  }
  h3 {
    margin-bottom: 30px;
  }

  .button-outline {
    background-color: #fff !important;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    font-size: 15px;
    span {
      font-size: 15px;
      padding: 0px;
    }
    padding: 8px 20px;
    font-size: 15px;
    line-height: 19px;
    font-family: var(--heading-font-family), var(--default-font-family);
    font-weight: 500;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
}
