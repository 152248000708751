@import "styles/mixins/index";
.employment-form-section {
  padding: map-get($pixel, md) * 5 map-get($pixel, md) * 5 + 5px;
  border: 1px solid var(--border-color-8);
  border-radius: 8px;
  margin: 0 0 60px 0;
  @include breakpoint("max-md") {
    margin: 0 0 50px 0;
    padding: map-get($pixel, md) * 4 map-get($pixel, md) * 3;
  }
  @include breakpoint("max-sm") {
    margin: 0 0 30px 0;
    padding: map-get($pixel, sm) * 3;
  }
  .three-col-grid {
    @include grid-layout;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    justify-content: space-between;
    padding: 0 0 map-get($pixel, md) * 2 0;
    @include breakpoint("max-md") {
      grid-template-columns: repeat(2, 1fr);
    }
    @include breakpoint("max-sm") {
      grid-template-columns: repeat(1, 1fr);
    }
    br {
      @include breakpoint("max-md") {
        display: none;
      }
    }
    @include breakpoint("max-md") {
      .form-control:last-child {
        grid-column: 1 / 3;
      }
    }
    @include breakpoint("max-sm") {
      .form-control:last-child {
        grid-column: auto;
      }
    }
  }
  .form-submit-button {
    button {
      background-color: var(--primary-color);
      color: var(--bg-white);
      border: none;
      padding: 8px 30px;
      height: 48px;
      @include heading-4;
      border-radius: 5px;
    }
  }
  .sec-col-grid {
    @include grid-layout;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    justify-content: space-between;
    padding: 0 0 map-get($pixel, md) * 2 0;
    @include breakpoint("max-md") {
      grid-template-columns: repeat(1, 1fr);
    }
    .leaving-text {
      margin: 0 0 20px 0;
      textarea {
        height: 72px;
      }
    }
    .reason-text {
      textarea {
        height: 150px;
      }
    }
  }
  .tow-col-grid {
    @include grid-layout;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    justify-content: space-between;
    padding: 0 0 map-get($pixel, md) * 2 0;
    @include breakpoint("max-sm") {
      grid-template-columns: repeat(1, 1fr);
    }
    @include breakpoint("max-md") {
      .tow-col-grid-items:nth-child(1) {
        .form-control:last-child {
          grid-column: auto;
        }
      }
    }
    @include breakpoint("max-sm") {
      .tow-col-grid-items:nth-child(1) {
        .form-control:last-child {
          grid-column: auto;
        }
      }
    }
    .tow-col-grid-items:nth-child(1) {
      @include grid-layout;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
      @include breakpoint("max-md") {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
  .dropdown-show {
    height: 100vh;
    z-index: 999999;
  }
  .dropdown-hidden {
    z-index: 999999;
  }
  .form-control {
    position: map-get($layout, relative);
    p {
      color: var(--bg-danger);
      font-size: 12px;
    }
    .show-items {
      button {
        font-size: 15px;
        line-height: 19px;
        font-family: var(--heading-font-family), var(--default-font-family);
        font-weight: 500;
        padding: 12px 17px;
        border: 1px solid var(--light-border-color);
        color: var(--black-5-light);
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: transparent;
        font-weight: 400;
        width: 100%;
        position: relative;
        transition: 0.2s ease-in-out;
        &:focus {
          border: 1px solid var(--primary-color);
        }
      }
    }
  }
  .icon-alignment-input {
    position: absolute;
    @include pointer;
    @include flex-center;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    i {
      color: var(--gray-text-color);
    }
  }
  .four-col-grid {
    @include grid-layout;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    justify-content: space-between;
    padding: 0 0 map-get($pixel, md) * 2 0;
    @include breakpoint("max-md") {
      grid-template-columns: repeat(2, 1fr);
    }
    @include breakpoint("max-sm") {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  .terms-and-conditions {
    padding: 0 0 map-get($pixel, md) * 1 0;
    label {
      @include flex-center;
      @include text-xs-normal;
      color: var(--invert-primary-color);
      span {
        padding-left: 10px;
        display: flex;
        flex-wrap: wrap;
        .text-danger {
          padding-left: 2px;
          color: var(--secondary-color);
        }
      }
    }
  }
  .re-captcha {
    @media (max-width: 350px) {
      transform: scale(0.7);
      transform-origin: 0 0;
    }
  }
  .form-submit-button {
    margin-top: 20px;
    @media (max-width: 350px) {
      margin-top: 0px;
    }
  }
  .note-text {
    padding: 0 0 10px 0;
    span {
      font-size: 12px;
      color: var(--bg-danger);
    }
    p {
      margin: 0;
      @include heading-6;
      @include font-normal;
      color: var(--invert-primary-color);
      line-height: 22px;
      @include breakpoint("max-sm") {
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
  .one-col-grid {
    @include grid-layout;
    padding: 0 0 map-get($pixel, md) * 2 0;
    grid-template-columns: repeat(1, 1fr);
    textarea {
      height: 150px;
    }
  }
  .select-dropdown {
    font-size: 14px;
    font-family: var(--heading-font-family), var(--default-font-family);
    padding: 11px 15px;
    cursor: pointer;
    font-weight: 400;
    color: var(--black-3);
    transition: 0.3s ease-in-out;
    line-height: 16px;
  }
  input[type="checkbox"] {
    width: 15px;
    height: 15px;
    margin: 0;
    padding: 0;
  }
}