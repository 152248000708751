@import "styles/mixins/index";

.checkout-details-section {
    padding: map-get($pixel, md) * 5 0 map-get($pixel, md) * 7 0;

    @include breakpoint("max-sm") {
        padding: map-get($pixel, sm) * 3 0;
    }

    .page-title {
        padding: 0 0 map-get($pixel, sm) * 3 + 1px 0;

        h1 {
            @include heading-9-medium;
            margin: 0;
            color: var(--black-text-color);
        }
    }

    .enter-code-coupon {
        background-color: var(--checkout-page-message-bg-color);
        padding: map-get($pixel, md) * 2 + 6px map-get($pixel, md) * 3 + 5px;
        margin: 0 0 map-get($pixel, md) * 2 0;

        @include breakpoint("max-sm") {
            padding: map-get($pixel, sm) * 3;
        }

        p {
            @include heading-4;
            @include font-normal;
            @include pointer;
            color: var(--black-3);
        }

        span {
            color: var(--checkout-page-message-font-color);
        }
    }

    .apply-coupon-code {
        background-color: var(--bg-white);
        padding: map-get($pixel, md) * 2 + 6px map-get($pixel, md) * 3 + 5px;
        box-shadow: 0px 0px 20px rgba(157, 157, 157, 0.16);

        @include breakpoint("max-sm") {
            padding: map-get($pixel, sm) * 3;
        }

        .apply-coupon-code-style {
            max-width: 600px;

            .apply-coupon-relative {
                position: map-get($layout, relative);

                .remove-button {
                    position: absolute;
                    top: 0;
                    right: 0;

                    button {
                        padding: 12px 24px;
                        @include pointer;
                        background-color: var(--bg-light-500);
                        height: 46px;
                        border: none;
                        border-radius: 0 4px 4px 0;
                        @include heading-text-sm;
                        color: #fea133;
                        @include pointer;
                    }
                }
            }
        }
    }
}