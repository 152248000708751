@import "styles/mixins/index";

.previous-buys-section-wrapper {
  padding: map-get($pixel, md) * 5 0;
  .previous-buys-section-alignment {
    padding: 0 0 map-get($pixel, md) * 2 + 5px 0;
    @include breakpoint("max-sm") {
      padding: 0;
    }
    h1 {
      @include heading-3;
      color: var(--black-text-color);
      margin: 0 0 map-get($pixel, sm) 0;
      @include breakpoint("max-sm") {
        font-size: 24px;
        margin: 0;
      }
    }
    p {
      @include text-md-regular;
      color: #777;
      @include breakpoint("max-sm") {
        font-size: 17px;
      }
    }
  }
}
