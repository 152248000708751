@import "styles/mixins/index";

.listing-action-section {
  padding: map-get($pixel, md) * 5 0 map-get($pixel, md) * 2 0;
  @include breakpoint("max-sm") {
    padding: map-get($pixel, md) * 3 + 6px 0 map-get($pixel, md) * 3 0;
  }

  .listing-grid-wrapper {
    @include flex-between;
    @include breakpoint("max-md") {
    }
    @include breakpoint("max-sm") {
      // @include flex-between;
      display: contents;
    }

    .found-items {
      width: 100%;
      p {
        @include text-md;
        color: var(--gray-400);
        @include breakpoint("max-md") {
          margin: 0 0 0 0;
          display: none;
        }
        @include breakpoint("max-sm") {
          display: none;
        }
        span {
          color: var(--secondary-color);
        }
      }
      .product-count-mobile-screen {
        @include text-md;
        color: var(--secondary-color);
        display: none;
        @include breakpoint("max-md") {
          display: flex;
          justify-content: space-between;
          margin-right: 20px;
        }
        @include breakpoint("max-sm") {
          display: flex;
          justify-content: space-between;
          margin-bottom: 15px;
          margin-right: 0px;
        }
        .add-all-to-cart-button {
          background-color: transparent;
          border: 1px solid var(--primary-color);

          padding: 10px 20px;
          margin-right: 20px;
          @include breakpoint("max-sm") {
            margin-right: 0px;
          }
          span {
            color: var(--primary-color) !important;
          }
        }
        .mobile-filter-button {
          display: none;
          @include breakpoint("max-md") {
            display: block;
          }
          @include breakpoint("max-sm") {
            display: block;
          }
          button {
            padding: 9px 13px;
            border: 1px solid var(--border-color-6);
            border-radius: calc(5px - 4px);
            background-color: transparent;
            border-radius: calc(5px - 1px);

            @include flex-center;
            @include heading-text-sm-normal;
            @include pointer;
            span {
              padding-left: 6px;
              color: #1d1d1d !important;
            }
          }
        }
      }
    }
    .all-action-wrapper {
      @include flex-center;
      @include breakpoint("max-md") {
        justify-content: space-between;
      }
      @media only screen and (max-width: 450px) {
        flex-wrap: wrap;
      }
      .apply-coupon {
        button {
          background-color: var(--primary-color);
          color: var(--bg-white);
          border: none;
          min-width: 135px;
          @include font-medium;
        }
      }
      .show-items {
        margin-right: map-get($pixel, md) * 2;
        position: relative;
        @media only screen and (max-width: 450px) {
          margin: 5px 0;
        } 
        .sort-by-dropdown-align {
          position: absolute;
          width: 100%;
        }
        button {
          background-color: transparent;
          color: #1d1d1d;
          @include flex-center;
          width: 178px;
          border: 1px solid var(--border-color-6);
          @include breakpoint("max-sm") {
            width: 150px;
          }
          span {
            padding: 0 map-get($pixel, sm) + 1px;
            // display: block;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            width: 100%;
            text-align: left;
          }
        }
      }
      .show-items:last-child {
        margin-right: 0;
      }
      button {
        padding: 9px 16px;
        @include heading-text-sm-normal;
        border-radius: calc(5px - 1px);
      }
      .primary-outlined-button {
        background-color: transparent;
        border: 1px solid var(--primary-color);
        color: var(--primary-color);
        padding: 10px 20px;
        margin-right: 20px;
      }
    }
  }

  .restricted {
    display: flex;
    justify-content: flex-end;
    margin-top: 6px;
    p {
      color: var(--bg-danger);
    }
  }
}

.mobile-filter-hidden {
  transform: translateX(100%);
  transition: 0.3s ease-in-out;
}

.mobile-filter-show {
  transform: translateX(0%);
  transition: 0.3s ease-in-out;
}

.mobile-filter {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: var(--bg-white);
  overflow: auto;
  overflow-x: hidden;
  left: 0;
  top: 0;
  z-index: 999999;
  .mobile-filter-body {
    padding: map-get($pixel, sm) * 5 map-get($pixel, sm) * 4;
    .all-product-wrapper {
      padding: 24px 0 0 0;
    }
    .sidebar-item-list {
      padding: map-get($pixel, sm) * 5 0;
      border-bottom: 1px solid var(--border-color-5);
      .ift-item-with-checkbox:last-child {
        padding: 0;
      }
      .ift-item-with-checkbox {
        @include flex-center;
        padding: 0 0 map-get($pixel, sm) * 2 + 2px 0;
        div {
          @include flex-center;
          input[type="checkbox"] {
            width: 15px;
            height: 15px;
            margin: 0;
            padding: 0;
          }
          span {
            @include text;
            color: var(--gray-400);
            display: block;
            padding-left: map-get($pixel, sm) + 2px;
          }
        }
      }
    }
    .sidebar-toggle-item {
      padding: map-get($pixel, sm) * 5 0;
      border-bottom: 1px solid var(--border-color-5);
      .range-details {
        padding: map-get($pixel, sm) * 4 0 0 0;
        p,
        span {
          @include text;
          color: var(--gray-400);
        }
        span {
          @include font-semi-bold;
        }
      }
      .range-style {
        .slider {
          -webkit-appearance: none;
          width: 100%;
          height: 4px;
          background: #bfbfbf;
          outline: none;
          -webkit-transition: 0.2s;
          border-radius: 999px;
          transition: opacity 0.2s;
        }

        .slider:hover {
          opacity: 1;
        }

        .slider::-webkit-slider-thumb {
          -webkit-appearance: none;
          appearance: none;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background: #04aa6d;
          cursor: pointer;
        }

        .slider::-moz-range-thumb {
          width: 12px;
          border-radius: 50%;
          height: 12px;
          background: #04aa6d;
          cursor: pointer;
        }
      }
    }
    .shop-by-price {
      padding: 0 0 map-get($pixel, md) + 5px 0;
      @include flex-between;
      h2 {
        @include heading-7-medium;
        color: var(--gray-400);
      }
      i {
        color: var(--light-icon);
      }
    }

    .listing-page-sidebar {
      .main-product {
        .all-product-show {
          max-height: 0px;
          overflow: hidden;
          @include ease-in-out;
        }
        .all-product-hidden {
          max-height: calc(100vh - 0px);
          @include ease-in-out;
          overflow: hidden;
        }
      }
      .all-product-wrapper {
        padding: 24px 0 0 0;
      }
      .child-department-list {
        padding-left: 28px;
        .ift-item-with-checkbox {
          @include flex-center;
          position: relative;
          padding: 0 0 map-get($pixel, sm) * 2 + 2px 0;
          label {
            @include flex-center;
          }
          span {
            @include text;
            color: var(--gray-400);
            display: block;
            padding-left: map-get($pixel, sm) + 2px;
          }
        }
      }
      .sidebar-title-alignment {
        @include flex-center;
        padding: 0 0 map-get($pixel, md) * 2 0;
        justify-content: space-between;
        @include pointer;
        border-bottom: 1px solid var(--border-color-5);
        span {
          @include heading-7-medium;
          color: var(--gray-400);
          display: block;
        }
        i {
          color: var(--light-icon);
          transition: 0.3s ease-in-out;
        }
      }
      .add-product {
        @include flex-center;
        justify-content: space-between;
        padding: 0 0 map-get($pixel, sm) * 4 0;
        .title-alignment {
          @include flex-center;
          position: relative;
          input[type="checkbox"] {
            width: 15px;
            height: 15px;
            margin: 0;
            padding: 0;
          }
          label {
            @include flex-center;
          }
          span {
            @include text;
            color: var(--gray-400);
            display: block;
            padding-left: map-get($pixel, sm) * 2 + 2px;
          }
        }
        .add-icon {
          @include pointer;
          i {
            color: var(--gray-text-color);
          }
        }
      }
    }
  }
  .mobile-filter-header {
    padding: map-get($pixel, sm) * 3 map-get($pixel, sm) * 4;
    position: map-get($layout, relative);
    background-color: var(--primary-color);
    > p {
      @include heading-4;
      @include text-center;
      margin: 0;
      color: var(--bg-white);
    }
    .filter-close {
      img {
        @include pointer;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}
