.main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid black;
  margin: 0 auto;
  background-color: lightgray;
  @media (min-width: 767px) {
    width: 60%;
  }
  @media (max-width: 767px) {
    margin: 0 40px;
  }
  @media (max-width: 540px) {
    margin: 0 20px;
  }
  .button-container {
    background-color: gray;
    width: 100%;
    // border: 2px solid black;
    span {
      padding: 2rem;
      @media screen and (max-width: 575px) {
        padding: 1rem;
      }
    }
  }
}
.pdf-container {
  overflow: auto;
  @media screen and (min-width: 991px) {
    min-height: 50rem;
  }
  .pdf-inner {
    transform: scale(1);
    @media screen and (max-width: 991px) {
      canvas {
        width: 100% !important;
        height: auto !important;
        display: block !important;
      }
    }
  }
}