@import "styles/mixins/index";

.your-cart-section {
  .cart-grid {
    @include grid-layout;
    padding: map-get($pixel, md) * 2 0 map-get($pixel, md) * 7 +
      map-get($pixel, sm) 0;
    grid-template-columns: minmax(0, 1fr) 504px;
    gap: 0 map-get($pixel, md) * 4 + map-get($pixel, sm);

    @include breakpoint("max-xl") {
      grid-template-columns: minmax(0, 1fr) 404px;
    }

    @include breakpoint("max-md") {
      grid-template-columns: repeat(1, 1fr);
      gap: 60px 0;
    }

    @include breakpoint("max-sm") {
      grid-template-columns: repeat(1, 1fr);
      gap: 60px 0;
      padding: 20px 0 40px 0;
    }

    @include breakpoint("max-sm") {
      grid-template-columns: repeat(1, 1fr);
      gap: 20px 0;
    }
  }
}

.your-cart-section-align {
  .cart-title {
    padding: map-get($pixel, md) * 5 0 0 0;

    @include breakpoint("max-sm") {
      padding: map-get($pixel, md) * 3 0 0 0;
    }

    h1 {
      @include heading-9-medium;
      color: var(--black-text-color);
      margin: 0 0 map-get($pixel, md) * 2 - 1px 0;

      @include breakpoint("max-sm") {
        @include heading-5;
      }
    }
  }

  .page-grid-align {
    @include flex-between;
    width: calc(100% - 547px);

    @include breakpoint("max-xl") {
      min-width: calc(100% - 450px);
    }

    @include breakpoint("max-md") {
      width: 100%;
      @include grid-layout;
      grid-template-columns: repeat(1, 1fr);
      gap: 20px;
    }

    .cart-action-align {
      @include flex-center;

      @include breakpoint("max-sm") {
        grid-template-columns: repeat(2, 1fr);
        display: grid;
        gap: 20px 0;
      }
      @media only screen and (max-width: 350px) {
        grid-template-columns: repeat(1, 1fr);
        gap: 10px 0;
      }

      .clear-cart {
        .clear-icon {
          cursor: pointer;
        }
        .disabled {
          pointer-events: none;
          cursor: default;
          opacity: 0.6;
        }
      }

      button {
        @include heading-text-sm-normal;
        @include font-medium;
        height: 35px;
        padding: 8px 20px;
        line-height: 13px;
        border-radius: calc(5px - 1px);

        @include breakpoint("max-sm") {
          padding: 8px 17px;
        }
      }

      .check-reward {
        margin-right: map-get($pixel, sm) * 3;

        @include breakpoint("max-sm") {
          margin: 0;
        }

        button {
          border: none;
          background-color: var(--secondary-color);
          color: var(--bg-white);
        }
      }

   
  
      .continue-shopping {
        margin-right: map-get($pixel, sm) * 3;
        min-width: 175px;
        button {
          background-color: var(--primary-color);
          color: var(--bg-white);
          padding: 11px 12px;
          border: none;
          border-radius: 7px;
          border: none;
          @include flex-center;
          @include heading-text-sm;
          span {
            padding-left: 5px;
          }
        }
      }
    }
    .add-to-list-dropdown {
      @include breakpoint("max-sm") {
        right: -10px;
      }
    }
  
    div {
      p {
        @include text;
        margin: 0;
        color: var(--gray-7-light);
      }
    }
  }
}